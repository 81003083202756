/* tslint:disable */
/* eslint-disable */
/**
 * LightFi API
 *  <h2>Supplementary Documentation</h2> See also documentation at <a href=https://docs.lightfi.io/API/ >https://docs.lightfi.io/API/</a> for guidance.  <h2>Authorisation for the API and this test page</h2> You will need your OAuth `client_id` and `client_secret` as well as a valid user login to use the API (contact LightFi to obtain these).  You can test the API directly from this page:   - Click the \"Authorize\" button (below this text)   - Enter your `client_id` and `client_secret` and click \"Authorize\"   - You will be redirected to a login page to enter your LightFi username (the email address for your account) and password   - On successful login you will be redirected back to this page   - You can now test the API routes directly  Notes:   - Using this page to test queries returning large amounts of data will be slow, much slower than e.g. using the curl command   - You can obtain an access token from this page to use in another client for testing by copying it from the example curl request   - The `access_token` is only valid for a short period of time (`expiry [\"exp\"]` can be viewed in the **JWT** token itself using any JWT decoding tool/library e.g. <a href=https://jwt.io/>https://jwt.io/</a> ). When the access token expires you will need to logout/login again on the API documentation page.   - In order to allow programmatic access for a longer period of time you need the `refresh_token`, this can be obtained, for example, using Postman (see <a href=https://docs.lightfi.io/API/postman_refresh_token/>here</a>).
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface APsConfig
 */
export interface APsConfig {
  /**
   *
   * @type {Array<string>}
   * @memberof APsConfig
   */
  address_allowlist?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof APsConfig
   */
  address_denylist?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof APsConfig
   */
  ssid_allowlist?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof APsConfig
   */
  ssid_denylist?: Array<string>;
}
/**
 *
 * @export
 * @interface AccessPointInfo
 */
export interface AccessPointInfo {
  /**
   *
   * @type {string}
   * @memberof AccessPointInfo
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof AccessPointInfo
   */
  ssid: string;
  /**
   *
   * @type {number}
   * @memberof AccessPointInfo
   */
  channel: number;
  /**
   *
   * @type {number}
   * @memberof AccessPointInfo
   */
  last_seen: number;
  /**
   *
   * @type {boolean}
   * @memberof AccessPointInfo
   */
  listening: boolean;
}
/**
 *
 * @export
 * @interface BacnetGatewaySettings
 */
export interface BacnetGatewaySettings {
  /**
   *
   * @type {string}
   * @memberof BacnetGatewaySettings
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettings
   */
  building_network_no: number;
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettings
   */
  vlan_network_no: number;
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettings
   */
  vlan_device_address: number;
  /**
   *
   * @type {Array<BacnetSensorSettings>}
   * @memberof BacnetGatewaySettings
   */
  sensors: Array<BacnetSensorSettings>;
}
/**
 *
 * @export
 * @interface BacnetGatewaySettingsCreate
 */
export interface BacnetGatewaySettingsCreate {
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettingsCreate
   */
  building_network_no: number;
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettingsCreate
   */
  vlan_network_no: number;
}
/**
 *
 * @export
 * @interface BacnetGatewaySettingsUpdate
 */
export interface BacnetGatewaySettingsUpdate {
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettingsUpdate
   */
  building_network_no?: number;
  /**
   *
   * @type {number}
   * @memberof BacnetGatewaySettingsUpdate
   */
  vlan_network_no?: number;
}
/**
 *
 * @export
 * @interface BacnetSensorSettings
 */
export interface BacnetSensorSettings {
  /**
   *
   * @type {string}
   * @memberof BacnetSensorSettings
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof BacnetSensorSettings
   */
  bacnet_id: number;
  /**
   *
   * @type {number}
   * @memberof BacnetSensorSettings
   */
  vlan_device_address: number;
}
/**
 *
 * @export
 * @interface BacnetSensorSettingsCreate
 */
export interface BacnetSensorSettingsCreate {
  /**
   *
   * @type {number}
   * @memberof BacnetSensorSettingsCreate
   */
  bacnet_id: number;
  /**
   *
   * @type {number}
   * @memberof BacnetSensorSettingsCreate
   */
  vlan_device_address?: number;
}
/**
 *
 * @export
 * @interface BacnetSensorSettingsUpdate
 */
export interface BacnetSensorSettingsUpdate {
  /**
   *
   * @type {number}
   * @memberof BacnetSensorSettingsUpdate
   */
  bacnet_id?: number;
  /**
   *
   * @type {number}
   * @memberof BacnetSensorSettingsUpdate
   */
  vlan_device_address?: number;
}
/**
 *
 * @export
 * @interface BacnetSettings
 */
export interface BacnetSettings {
  /**
   *
   * @type {BacnetSensorSettings}
   * @memberof BacnetSettings
   */
  sensor?: BacnetSensorSettings;
  /**
   *
   * @type {BacnetGatewaySettings}
   * @memberof BacnetSettings
   */
  gateway: BacnetGatewaySettings;
}
/**
 *
 * @export
 * @interface BacnetSettingsCreate
 */
export interface BacnetSettingsCreate {
  /**
   *
   * @type {BacnetSensorSettingsCreate}
   * @memberof BacnetSettingsCreate
   */
  sensor?: BacnetSensorSettingsCreate;
  /**
   *
   * @type {BacnetGatewaySettingsCreate}
   * @memberof BacnetSettingsCreate
   */
  gateway?: BacnetGatewaySettingsCreate;
}
/**
 *
 * @export
 * @interface BacnetSettingsUpdate
 */
export interface BacnetSettingsUpdate {
  /**
   *
   * @type {BacnetSensorSettingsUpdate}
   * @memberof BacnetSettingsUpdate
   */
  sensor?: BacnetSensorSettingsUpdate;
  /**
   *
   * @type {BacnetGatewaySettingsUpdate}
   * @memberof BacnetSettingsUpdate
   */
  gateway?: BacnetGatewaySettingsUpdate;
}
/**
 *
 * @export
 * @interface BandPct
 */
export interface BandPct {
  /**
   *
   * @type {string}
   * @memberof BandPct
   */
  band: string;
  /**
   *
   * @type {number}
   * @memberof BandPct
   */
  pct: number;
}
/**
 *
 * @export
 * @interface BandsRSSI
 */
export interface BandsRSSI {
  /**
   *
   * @type {number}
   * @memberof BandsRSSI
   */
  twoGHz?: number;
  /**
   *
   * @type {number}
   * @memberof BandsRSSI
   */
  fiveGHz?: number;
}
/**
 *
 * @export
 * @interface BaseAccessPoint
 */
export interface BaseAccessPoint {
  /**
   *
   * @type {string}
   * @memberof BaseAccessPoint
   */
  address_pattern: string;
  /**
   *
   * @type {boolean}
   * @memberof BaseAccessPoint
   */
  listening: boolean;
  /**
   *
   * @type {BandsRSSI}
   * @memberof BaseAccessPoint
   */
  rssis: BandsRSSI;
  /**
   *
   * @type {Array<AccessPointInfo>}
   * @memberof BaseAccessPoint
   */
  aps: Array<AccessPointInfo>;
}
/**
 *
 * @export
 * @interface DailyMetricItem
 */
export interface DailyMetricItem {
  /**
   *
   * @type {string}
   * @memberof DailyMetricItem
   */
  id: string;
  /**
   *
   * @type {VarName}
   * @memberof DailyMetricItem
   */
  varName?: VarName;
  /**
   *
   * @type {string}
   * @memberof DailyMetricItem
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof DailyMetricItem
   */
  location?: string;
  /**
   *
   * @type {number}
   * @memberof DailyMetricItem
   */
  pts?: number;
  /**
   *
   * @type {number}
   * @memberof DailyMetricItem
   */
  avg?: number;
  /**
   *
   * @type {number}
   * @memberof DailyMetricItem
   */
  max?: number;
  /**
   *
   * @type {number}
   * @memberof DailyMetricItem
   */
  min?: number;
  /**
   *
   * @type {number}
   * @memberof DailyMetricItem
   */
  tot?: number;
  /**
   *
   * @type {number}
   * @memberof DailyMetricItem
   */
  utl?: number;
  /**
   *
   * @type {Array<HourlyMetric>}
   * @memberof DailyMetricItem
   */
  hours?: Array<HourlyMetric>;
}

/**
 *
 * @export
 * @enum {string}
 */

export const DailyMetricProjectTypes = {
  All: 'all',
  Hours: 'hours',
  Days: 'days',
} as const;

export type DailyMetricProjectTypes =
  typeof DailyMetricProjectTypes[keyof typeof DailyMetricProjectTypes];

/**
 *
 * @export
 * @interface DataItem
 */
export interface DataItem {
  /**
   *
   * @type {VarName}
   * @memberof DataItem
   */
  varName: VarName;
  /**
   *
   * @type {number}
   * @memberof DataItem
   */
  time?: number;
  /**
   *
   * @type {number}
   * @memberof DataItem
   */
  value?: number;
  /**
   *
   * @type {Detail}
   * @memberof DataItem
   */
  detail?: Detail | null;
  /**
   *
   * @type {number}
   * @memberof DataItem
   */
  raw?: number;
  /**
   *
   * @type {number}
   * @memberof DataItem
   */
  new?: number;
}

/**
 *
 * @export
 * @interface DataTreeItem
 */
export interface DataTreeItem {
  /**
   *
   * @type {string}
   * @memberof DataTreeItem
   */
  id: string;
  /**
   *
   * @type {VarName}
   * @memberof DataTreeItem
   */
  varName: VarName;
  /**
   *
   * @type {number}
   * @memberof DataTreeItem
   */
  time?: number;
  /**
   *
   * @type {number}
   * @memberof DataTreeItem
   */
  value?: number;
  /**
   *
   * @type {Detail}
   * @memberof DataTreeItem
   */
  detail?: Detail | null;
  /**
   *
   * @type {number}
   * @memberof DataTreeItem
   */
  raw?: number;
  /**
   *
   * @type {number}
   * @memberof DataTreeItem
   */
  new?: number;
  /**
   *
   * @type {string}
   * @memberof DataTreeItem
   */
  location?: string;
  /**
   *
   * @type {Position}
   * @memberof DataTreeItem
   */
  position?: Position;
}

/**
 *
 * @export
 * @interface DataTreeItems
 */
export interface DataTreeItems {
  /**
   *
   * @type {Array<DataTreeItem>}
   * @memberof DataTreeItems
   */
  data?: Array<DataTreeItem>;
}
/**
 *
 * @export
 * @interface Detail
 */
export interface Detail {
  /**
   *
   * @type {Array<Position>}
   * @memberof Detail
   */
  positions: Array<Position>;
}
/**
 *
 * @export
 * @interface GatewayConfig
 */
export interface GatewayConfig {
  /**
   *
   * @type {APsConfig}
   * @memberof GatewayConfig
   */
  wifi_config?: APsConfig;
}
/**
 *
 * @export
 * @interface GeoJSON
 */
export interface GeoJSON {
  /**
   *
   * @type {string}
   * @memberof GeoJSON
   */
  type: string;
  /**
   *
   * @type {Array<object>}
   * @memberof GeoJSON
   */
  features: Array<any>;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface HistoricData
 */
export interface HistoricData {
  /**
   *
   * @type {Array<number>}
   * @memberof HistoricData
   */
  time: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof HistoricData
   */
  value: Array<number>;
}
/**
 *
 * @export
 * @interface HourlyMetric
 */
export interface HourlyMetric {
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  h: number;
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  pts: number;
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  avg?: number;
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  max?: number;
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  min?: number;
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  utl?: number;
  /**
   *
   * @type {number}
   * @memberof HourlyMetric
   */
  tot?: number;
}
/**
 *
 * @export
 * @interface KeyPair
 */
export interface KeyPair {
  /**
   *
   * @type {string}
   * @memberof KeyPair
   */
  PrivateKey: string;
  /**
   *
   * @type {string}
   * @memberof KeyPair
   */
  PublicKey: string;
}
/**
 *
 * @export
 * @interface LocationDirectChildren
 */
export interface LocationDirectChildren {
  /**
   *
   * @type {Array<PropLocationItem>}
   * @memberof LocationDirectChildren
   */
  locations?: Array<PropLocationItem>;
  /**
   *
   * @type {Array<SensorLatest>}
   * @memberof LocationDirectChildren
   */
  sensors?: Array<SensorLatest>;
}
/**
 *
 * @export
 * @interface LocationTreeItems
 */
export interface LocationTreeItems {
  /**
   *
   * @type {Array<PropLocationItem>}
   * @memberof LocationTreeItems
   */
  locations?: Array<PropLocationItem>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LocationType = {
  Client: 'client',
  Zone: 'zone',
  Building: 'building',
  FloorLevel: 'floorLevel',
  Area: 'area',
} as const;

export type LocationType = typeof LocationType[keyof typeof LocationType];

/**
 *
 * @export
 * @interface NewUserDetails
 */
export interface NewUserDetails {
  /**
   *
   * @type {string}
   * @memberof NewUserDetails
   */
  access_level: string;
  /**
   *
   * @type {number}
   * @memberof NewUserDetails
   */
  expiry?: number;
  /**
   *
   * @type {string}
   * @memberof NewUserDetails
   */
  email: string;
}
/**
 *
 * @export
 * @interface OccSignaturesDetail
 */
export interface OccSignaturesDetail {
  /**
   *
   * @type {Array<Position>}
   * @memberof OccSignaturesDetail
   */
  positions: Array<Position>;
}
/**
 *
 * @export
 * @interface Period
 */
export interface Period {
  /**
   *
   * @type {string}
   * @memberof Period
   */
  start: string;
  /**
   *
   * @type {string}
   * @memberof Period
   */
  end: string;
}
/**
 *
 * @export
 * @interface Position
 */
export interface Position {
  /**
   * Lattitude
   * @type {number}
   * @memberof Position
   */
  lat?: number;
  /**
   * Longitude
   * @type {number}
   * @memberof Position
   */
  lng?: number;
  /**
   * Floor level in building (mostly unused)
   * @type {string}
   * @memberof Position
   */
  floor?: string;
  /**
   * Associated floorplan polygon id
   * @type {string}
   * @memberof Position
   */
  polygon?: string;
  /**
   * Height above floor (for occSignatures sensors)
   * @type {number}
   * @memberof Position
   */
  height?: number;
  /**
   * Angle from North (for occSignatures sensors)
   * @type {number}
   * @memberof Position
   */
  azimuth?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ProjectData = {
  All: 'all',
  Time: 'time',
  Value: 'value',
  Detail: 'detail',
  Raw: 'raw',
  New: 'new',
  Location: 'location',
  Position: 'position',
} as const;

export type ProjectData = typeof ProjectData[keyof typeof ProjectData];

/**
 *
 * @export
 * @enum {string}
 */

export const ProjectDirectChildren = {
  All: 'all',
  Id: 'id',
  Name: 'name',
  ShortName: 'shortName',
  Type: 'type',
  Location: 'location',
  Position: 'position',
  FloorPlan: 'floorPlan',
  TimeZone: 'timeZone',
  Metadata: 'metadata',
  Latest: 'latest',
  Port: 'port',
  HwVersion: 'hwVersion',
  FwVersion: 'fwVersion',
  Gateway: 'gateway',
  Data: 'data',
  Time: 'time',
  Value: 'value',
  Detail: 'detail',
  Raw: 'raw',
  New: 'new',
} as const;

export type ProjectDirectChildren =
  typeof ProjectDirectChildren[keyof typeof ProjectDirectChildren];

/**
 *
 * @export
 * @enum {string}
 */

export const ProjectLocations = {
  All: 'all',
  Name: 'name',
  ShortName: 'shortName',
  Type: 'type',
  Location: 'location',
  Position: 'position',
  FloorPlan: 'floorPlan',
  TimeZone: 'timeZone',
  Metadata: 'metadata',
  Latest: 'latest',
} as const;

export type ProjectLocations = typeof ProjectLocations[keyof typeof ProjectLocations];

/**
 *
 * @export
 * @enum {string}
 */

export const ProjectSensorLatest = {
  All: 'all',
  Location: 'location',
  Name: 'name',
  ShortName: 'shortName',
  Type: 'type',
  TimeZone: 'timeZone',
  Port: 'port',
  HwVersion: 'hwVersion',
  FwVersion: 'fwVersion',
  Position: 'position',
  Gateway: 'gateway',
  Metadata: 'metadata',
  Latest: 'latest',
  Data: 'data',
  Time: 'time',
  Value: 'value',
  Detail: 'detail',
  Raw: 'raw',
  New: 'new',
} as const;

export type ProjectSensorLatest = typeof ProjectSensorLatest[keyof typeof ProjectSensorLatest];

/**
 *
 * @export
 * @enum {string}
 */

export const ProjectSensors = {
  All: 'all',
  Location: 'location',
  Name: 'name',
  ShortName: 'shortName',
  Type: 'type',
  TimeZone: 'timeZone',
  Port: 'port',
  HwVersion: 'hwVersion',
  FwVersion: 'fwVersion',
  Position: 'position',
  Gateway: 'gateway',
  Metadata: 'metadata',
  Latest: 'latest',
} as const;

export type ProjectSensors = typeof ProjectSensors[keyof typeof ProjectSensors];

/**
 *
 * @export
 * @interface PropLocationItem
 */
export interface PropLocationItem {
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  shortName?: string;
  /**
   *
   * @type {LocationType}
   * @memberof PropLocationItem
   */
  type?: LocationType;
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  location?: string;
  /**
   *
   * @type {Position}
   * @memberof PropLocationItem
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  floorPlan?: string | null;
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  timeZone?: string;
  /**
   * Last updated timestamp of each field
   * @type {object}
   * @memberof PropLocationItem
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof PropLocationItem
   */
  latest?: string;
}

/**
 *
 * @export
 * @interface PropLocationItemCreate
 */
export interface PropLocationItemCreate {
  /**
   *
   * @type {string}
   * @memberof PropLocationItemCreate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PropLocationItemCreate
   */
  shortName: string;
  /**
   *
   * @type {LocationType}
   * @memberof PropLocationItemCreate
   */
  type: LocationType;
  /**
   *
   * @type {string}
   * @memberof PropLocationItemCreate
   */
  location: string;
  /**
   *
   * @type {Position}
   * @memberof PropLocationItemCreate
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof PropLocationItemCreate
   */
  timeZone: string;
}

/**
 *
 * @export
 * @interface PropLocationItemIn
 */
export interface PropLocationItemIn {
  /**
   *
   * @type {string}
   * @memberof PropLocationItemIn
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PropLocationItemIn
   */
  shortName?: string;
  /**
   *
   * @type {LocationType}
   * @memberof PropLocationItemIn
   */
  type?: LocationType;
  /**
   *
   * @type {string}
   * @memberof PropLocationItemIn
   */
  location?: string;
  /**
   *
   * @type {Position}
   * @memberof PropLocationItemIn
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof PropLocationItemIn
   */
  timeZone?: string;
}

/**
 *
 * @export
 * @interface PropSensorItem
 */
export interface PropSensorItem {
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  shortName?: string;
  /**
   *
   * @type {Type}
   * @memberof PropSensorItem
   */
  type?: Type;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  timeZone?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  port?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  hwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  fwVersion?: string;
  /**
   *
   * @type {Position}
   * @memberof PropSensorItem
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  gateway?: string;
  /**
   * Last updated timestamp of each field
   * @type {object}
   * @memberof PropSensorItem
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof PropSensorItem
   */
  latest?: string;
}
/**
 *
 * @export
 * @interface PropSensorItemCreate
 */
export interface PropSensorItemCreate {
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  shortName?: string;
  /**
   *
   * @type {Position}
   * @memberof PropSensorItemCreate
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  gateway: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  timeZone?: string;
  /**
   *
   * @type {SubSensorType}
   * @memberof PropSensorItemCreate
   */
  type: SubSensorType;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  hwVersion: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemCreate
   */
  fwVersion?: string;
}

/**
 *
 * @export
 * @interface PropSensorItemIn
 */
export interface PropSensorItemIn {
  /**
   *
   * @type {string}
   * @memberof PropSensorItemIn
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemIn
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemIn
   */
  shortName?: string;
  /**
   *
   * @type {Position}
   * @memberof PropSensorItemIn
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemIn
   */
  gateway?: string;
  /**
   *
   * @type {string}
   * @memberof PropSensorItemIn
   */
  timeZone?: string;
}
/**
 *
 * @export
 * @interface ProvisionSensorClaimOut
 */
export interface ProvisionSensorClaimOut {
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  thingName: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  thingType: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  hw_ver?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  port?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorClaimOut
   */
  thingGroup?: string;
  /**
   *
   * @type {ProvisionerClaim}
   * @memberof ProvisionSensorClaimOut
   */
  claim: ProvisionerClaim;
}
/**
 *
 * @export
 * @interface ProvisionSensorIn
 */
export interface ProvisionSensorIn {
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  location: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  type: string;
  /**
   *
   * @type {Position}
   * @memberof ProvisionSensorIn
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  timeZone?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  hwVersion: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  fwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  port?: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionSensorIn
   */
  thingGroup?: string;
}
/**
 *
 * @export
 * @interface ProvisionerClaim
 */
export interface ProvisionerClaim {
  /**
   *
   * @type {string}
   * @memberof ProvisionerClaim
   */
  certificateId: string;
  /**
   *
   * @type {string}
   * @memberof ProvisionerClaim
   */
  certificatePem: string;
  /**
   *
   * @type {number}
   * @memberof ProvisionerClaim
   */
  expiration: number;
  /**
   *
   * @type {KeyPair}
   * @memberof ProvisionerClaim
   */
  keyPair: KeyPair;
}
/**
 *
 * @export
 * @interface ReportBandsData
 */
export interface ReportBandsData {
  /**
   *
   * @type {VarName}
   * @memberof ReportBandsData
   */
  varName: VarName;
  /**
   *
   * @type {ReportDataBandsSummary}
   * @memberof ReportBandsData
   */
  summary: ReportDataBandsSummary;
  /**
   *
   * @type {Array<WeekdayStats>}
   * @memberof ReportBandsData
   */
  weekdays: Array<WeekdayStats>;
  /**
   *
   * @type {Array<ReportSensorSummary>}
   * @memberof ReportBandsData
   */
  sensors: Array<ReportSensorSummary>;
}

/**
 *
 * @export
 * @interface ReportDataBandsSummary
 */
export interface ReportDataBandsSummary {
  /**
   *
   * @type {number}
   * @memberof ReportDataBandsSummary
   */
  desiredPct: number;
  /**
   *
   * @type {Array<BandPct>}
   * @memberof ReportDataBandsSummary
   */
  bands: Array<BandPct>;
}
/**
 *
 * @export
 * @interface ReportDataUtlSummary
 */
export interface ReportDataUtlSummary {
  /**
   *
   * @type {number}
   * @memberof ReportDataUtlSummary
   */
  utl: number;
  /**
   *
   * @type {number}
   * @memberof ReportDataUtlSummary
   */
  peakUtl: number;
  /**
   *
   * @type {number}
   * @memberof ReportDataUtlSummary
   */
  maxTheory: number;
  /**
   *
   * @type {number}
   * @memberof ReportDataUtlSummary
   */
  maxMoment: number;
}
/**
 *
 * @export
 * @interface ReportOutput
 */
export interface ReportOutput {
  /**
   *
   * @type {string}
   * @memberof ReportOutput
   */
  location: string;
  /**
   *
   * @type {Period}
   * @memberof ReportOutput
   */
  period: Period;
  /**
   *
   * @type {Array<number>}
   * @memberof ReportOutput
   */
  hours?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof ReportOutput
   */
  weekendsIncluded: boolean;
  /**
   *
   * @type {Array<ReportBandsData>}
   * @memberof ReportOutput
   */
  bands: Array<ReportBandsData>;
  /**
   *
   * @type {Array<ReportUtlData>}
   * @memberof ReportOutput
   */
  utl: Array<ReportUtlData>;
}
/**
 *
 * @export
 * @interface ReportSensorSummary
 */
export interface ReportSensorSummary {
  /**
   *
   * @type {string}
   * @memberof ReportSensorSummary
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ReportSensorSummary
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ReportSensorSummary
   */
  location: string;
  /**
   *
   * @type {number}
   * @memberof ReportSensorSummary
   */
  desiredPct: number;
  /**
   *
   * @type {Array<BandPct>}
   * @memberof ReportSensorSummary
   */
  bands: Array<BandPct>;
}
/**
 *
 * @export
 * @interface ReportUtlData
 */
export interface ReportUtlData {
  /**
   *
   * @type {VarName}
   * @memberof ReportUtlData
   */
  varName: VarName;
  /**
   *
   * @type {ReportDataUtlSummary}
   * @memberof ReportUtlData
   */
  summary: ReportDataUtlSummary;
  /**
   *
   * @type {Array<WeekdayUtl>}
   * @memberof ReportUtlData
   */
  weekdays: Array<WeekdayUtl>;
  /**
   *
   * @type {Array<ReportUtlSensorSummary>}
   * @memberof ReportUtlData
   */
  sensors: Array<ReportUtlSensorSummary>;
}

/**
 *
 * @export
 * @interface ReportUtlSensorSummary
 */
export interface ReportUtlSensorSummary {
  /**
   *
   * @type {string}
   * @memberof ReportUtlSensorSummary
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ReportUtlSensorSummary
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ReportUtlSensorSummary
   */
  location: string;
  /**
   *
   * @type {number}
   * @memberof ReportUtlSensorSummary
   */
  utl: number;
  /**
   *
   * @type {number}
   * @memberof ReportUtlSensorSummary
   */
  max: number;
  /**
   *
   * @type {number}
   * @memberof ReportUtlSensorSummary
   */
  relativeMax: number;
}
/**
 *
 * @export
 * @interface SensorLatest
 */
export interface SensorLatest {
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  shortName?: string;
  /**
   *
   * @type {Type}
   * @memberof SensorLatest
   */
  type?: Type;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  timeZone?: string;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  port?: string;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  hwVersion?: string;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  fwVersion?: string;
  /**
   *
   * @type {Position}
   * @memberof SensorLatest
   */
  position?: Position;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  gateway?: string;
  /**
   * Last updated timestamp of each field
   * @type {object}
   * @memberof SensorLatest
   */
  metadata?: object;
  /**
   *
   * @type {string}
   * @memberof SensorLatest
   */
  latest?: string;
  /**
   *
   * @type {Array<DataItem>}
   * @memberof SensorLatest
   */
  data?: Array<DataItem>;
}
/**
 *
 * @export
 * @interface SensorTreeItems
 */
export interface SensorTreeItems {
  /**
   *
   * @type {Array<PropSensorItem>}
   * @memberof SensorTreeItems
   */
  sensors?: Array<PropSensorItem>;
}
/**
 *
 * @export
 * @interface ShadowState
 */
export interface ShadowState {
  /**
   *
   * @type {ShadowStateDesired}
   * @memberof ShadowState
   */
  state: ShadowStateDesired;
}
/**
 *
 * @export
 * @interface ShadowStateDesired
 */
export interface ShadowStateDesired {
  /**
   *
   * @type {ShadowStateDesiredConfig}
   * @memberof ShadowStateDesired
   */
  desired: ShadowStateDesiredConfig;
}
/**
 *
 * @export
 * @interface ShadowStateDesiredConfig
 */
export interface ShadowStateDesiredConfig {
  /**
   *
   * @type {object}
   * @memberof ShadowStateDesiredConfig
   */
  config: object;
}
/**
 *
 * @export
 * @enum {string}
 */

export const SubSensorState = {
  Disabled: 'disabled',
  Enabling: 'enabling',
  Disabling: 'disabling',
  Enabled: 'enabled',
} as const;

export type SubSensorState = typeof SubSensorState[keyof typeof SubSensorState];

/**
 *
 * @export
 * @enum {string}
 */

export const SubSensorType = {
  BeaconCo2: 'BeaconCO2',
  BeaconEnergy: 'BeaconEnergy',
  BeaconOpenClose: 'BeaconOpenClose',
  BeaconPmco2: 'BeaconPMCO2',
  BeaconTempHumCo2: 'BeaconTempHumCO2',
  BeaconTempHum: 'BeaconTempHum',
  BeaconLightOnOff: 'BeaconLightOnOff',
  OccSignatures: 'OccSignatures',
  PirMotionEvents: 'PIRMotionEvents',
} as const;

export type SubSensorType = typeof SubSensorType[keyof typeof SubSensorType];

/**
 *
 * @export
 * @interface TokenCredentialsIn
 */
export interface TokenCredentialsIn {
  /**
   *
   * @type {string}
   * @memberof TokenCredentialsIn
   */
  credential_id: string;
  /**
   *
   * @type {string}
   * @memberof TokenCredentialsIn
   */
  client_id: string;
  /**
   *
   * @type {string}
   * @memberof TokenCredentialsIn
   */
  secret_key?: string;
}
/**
 *
 * @export
 * @interface TokenOutput
 */
export interface TokenOutput {
  /**
   *
   * @type {string}
   * @memberof TokenOutput
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof TokenOutput
   */
  token_type: string;
}

export enum BasicSensorType {
  virtual = 'virtual',
}
export enum GatewaySensorType {
  LfWiFiSensor = 'LF-WiFISensor',
}

/**
 *
 * @export
 * @interface Type
 */
export type Type = SubSensorType | GatewaySensorType | BasicSensorType;

/**

 *
 * @export
 * @interface UserListItem
 */
export interface UserListItem {
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  access_level: string;
  /**
   *
   * @type {number}
   * @memberof UserListItem
   */
  expiry?: number;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  location: string;
  /**
   *
   * @type {number}
   * @memberof UserListItem
   */
  inviteSent?: number;
  /**
   *
   * @type {string}
   * @memberof UserListItem
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof UserListItem
   */
  lastUpdate: number;
}
/**
 *
 * @export
 * @interface UserListOut
 */
export interface UserListOut {
  /**
   *
   * @type {Array<UserListItem>}
   * @memberof UserListOut
   */
  users: Array<UserListItem>;
}
/**
 *
 * @export
 * @interface UserPublic
 */
export interface UserPublic {
  /**
   *
   * @type {string}
   * @memberof UserPublic
   */
  access_level: string;
  /**
   *
   * @type {number}
   * @memberof UserPublic
   */
  expiry?: number;
  /**
   *
   * @type {string}
   * @memberof UserPublic
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof UserPublic
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserPublic
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<ValidationErrorLocInner>}
   * @memberof ValidationError
   */
  loc: Array<ValidationErrorLocInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {}
/**
 *
 * @export
 * @enum {string}
 */

export const VarName = {
  BaCnetReads: 'BACnetReads',
  BatteryLevelPct: 'batteryLevelPct',
  Co2ppm: 'CO2ppm',
  ClientsBle: 'clientsBLE',
  ClientsWiFi: 'clientsWiFi',
  EnergyInkWh: 'energyInkWh',
  FrequencyHz: 'frequencyHz',
  IlluminanceArb: 'illuminanceArb',
  MotionEvent: 'motionEvent',
  OccSignatures: 'occSignatures',
  OnlineStatus: 'onlineStatus',
  OutputLevelPct: 'outputLevelPct',
  PressuremBar: 'pressuremBar',
  ParticulateMatter: 'particulateMatter',
  RelativeHumidity: 'relativeHumidity',
  TemperatureC: 'temperatureC',
  VoCppb: 'VOCppb',
  Unknown: 'unknown',
} as const;

export type VarName = typeof VarName[keyof typeof VarName];

/**
 *
 * @export
 * @interface VisibleItems
 */
export interface VisibleItems {
  /**
   *
   * @type {Array<VisibleSubSensor>}
   * @memberof VisibleItems
   */
  subsensors: Array<VisibleSubSensor>;
  /**
   *
   * @type {Array<BaseAccessPoint>}
   * @memberof VisibleItems
   */
  wifi_aps: Array<BaseAccessPoint>;
  /**
   *
   * @type {WiFiConfig}
   * @memberof VisibleItems
   */
  wifi_config: WiFiConfig;
}
/**
 *
 * @export
 * @interface VisibleSubSensor
 */
export interface VisibleSubSensor {
  /**
   *
   * @type {string}
   * @memberof VisibleSubSensor
   */
  suggested_id: string;
  /**
   *
   * @type {string}
   * @memberof VisibleSubSensor
   */
  address: string;
  /**
   *
   * @type {SubSensorType}
   * @memberof VisibleSubSensor
   */
  type: SubSensorType;
  /**
   *
   * @type {string}
   * @memberof VisibleSubSensor
   */
  hwVersion?: string;
  /**
   *
   * @type {number}
   * @memberof VisibleSubSensor
   */
  rssi: number;
  /**
   *
   * @type {number}
   * @memberof VisibleSubSensor
   */
  last_seen: number;
  /**
   *
   * @type {SubSensorState}
   * @memberof VisibleSubSensor
   */
  status: SubSensorState;
}

/**
 *
 * @export
 * @interface WeekdayStats
 */
export interface WeekdayStats {
  /**
   *
   * @type {string}
   * @memberof WeekdayStats
   */
  day: string;
  /**
   *
   * @type {number}
   * @memberof WeekdayStats
   */
  min: number;
  /**
   *
   * @type {number}
   * @memberof WeekdayStats
   */
  avg: number;
  /**
   *
   * @type {number}
   * @memberof WeekdayStats
   */
  max: number;
}
/**
 *
 * @export
 * @interface WeekdayUtl
 */
export interface WeekdayUtl {
  /**
   *
   * @type {string}
   * @memberof WeekdayUtl
   */
  day: string;
  /**
   *
   * @type {number}
   * @memberof WeekdayUtl
   */
  utl: number;
  /**
   *
   * @type {number}
   * @memberof WeekdayUtl
   */
  peakUtl: number;
}
/**
 *
 * @export
 * @interface WiFiConfig
 */
export interface WiFiConfig {
  /**
   *
   * @type {APsConfig}
   * @memberof WiFiConfig
   */
  desired: APsConfig;
  /**
   *
   * @type {APsConfig}
   * @memberof WiFiConfig
   */
  reported: APsConfig;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Enable BACnet/IP with defined settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Enabling gateway may take a few minutes to apply.
     * @summary Add Bacnet Config
     * @param {string} sensorId
     * @param {BacnetSettingsCreate} bacnetSettingsCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBacnetConfigSensorsSensorIdBacnetPost: async (
      sensorId: string,
      bacnetSettingsCreate: BacnetSettingsCreate,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('addBacnetConfigSensorsSensorIdBacnetPost', 'sensorId', sensorId);
      // verify required parameter 'bacnetSettingsCreate' is not null or undefined
      assertParamExists(
        'addBacnetConfigSensorsSensorIdBacnetPost',
        'bacnetSettingsCreate',
        bacnetSettingsCreate
      );
      const localVarPath = `/sensors/{sensor_id}/bacnet`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bacnetSettingsCreate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add an entry to the DB for a new sensor (subsensors only supported)
     * @summary Add New Sensor
     * @param {string} sensorId
     * @param {PropSensorItemCreate} propSensorItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNewSensorSensorsSensorIdPost: async (
      sensorId: string,
      propSensorItemCreate: PropSensorItemCreate,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('addNewSensorSensorsSensorIdPost', 'sensorId', sensorId);
      // verify required parameter 'propSensorItemCreate' is not null or undefined
      assertParamExists(
        'addNewSensorSensorsSensorIdPost',
        'propSensorItemCreate',
        propSensorItemCreate
      );
      const localVarPath = `/sensors/{sensor_id}`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propSensorItemCreate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add a user to the location using their email address, sends an invite email, requires admin permissions for the given location.  May also be used to change an exsting user in the location tree of the admin.
     * @summary Add New User To Location
     * @param {string | null} locationId
     * @param {NewUserDetails} newUserDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNewUserToLocationUsersLocationIdAddPost: async (
      locationId: string | null,
      newUserDetails: NewUserDetails,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('addNewUserToLocationUsersLocationIdAddPost', 'locationId', locationId);
      // verify required parameter 'newUserDetails' is not null or undefined
      assertParamExists(
        'addNewUserToLocationUsersLocationIdAddPost',
        'newUserDetails',
        newUserDetails
      );
      const localVarPath = `/users/{location_id}/add`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        newUserDetails,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new location in the database. Sensors can then be placed in this location.
     * @summary Create New Location
     * @param {string | null} locationId
     * @param {PropLocationItemCreate} propLocationItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewLocationLocationsLocationIdPost: async (
      locationId: string | null,
      propLocationItemCreate: PropLocationItemCreate,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('createNewLocationLocationsLocationIdPost', 'locationId', locationId);
      // verify required parameter 'propLocationItemCreate' is not null or undefined
      assertParamExists(
        'createNewLocationLocationsLocationIdPost',
        'propLocationItemCreate',
        propLocationItemCreate
      );
      const localVarPath = `/locations/{location_id}`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propLocationItemCreate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the stored floorplan of the location
     * @summary Delete Location Floorplan
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLocationFloorplanLocationsLocationIdFloorplanDelete: async (
      locationId: string | null,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'deleteLocationFloorplanLocationsLocationIdFloorplanDelete',
        'locationId',
        locationId
      );
      const localVarPath = `/locations/{location_id}/floorplan`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a location, location must be empty (i.e. location must have no child locations, sensors etc.)
     * @summary Delete Location
     * @param {string | null} locationId
     * @param {boolean} [areYouSure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLocationLocationsLocationIdDelete: async (
      locationId: string | null,
      areYouSure?: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('deleteLocationLocationsLocationIdDelete', 'locationId', locationId);
      const localVarPath = `/locations/{location_id}`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (areYouSure !== undefined) {
        localVarQueryParameter['are_you_sure'] = areYouSure;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the given sensor (SubSensor only currently, must be disabled first)
     * @summary Delete Sensor
     * @param {string} sensorId
     * @param {boolean} [areYouSure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSensorSensorsSensorIdDelete: async (
      sensorId: string,
      areYouSure?: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('deleteSensorSensorsSensorIdDelete', 'sensorId', sensorId);
      const localVarPath = `/sensors/{sensor_id}`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (areYouSure !== undefined) {
        localVarQueryParameter['are_you_sure'] = areYouSure;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Generate API access token from temporary/API credentials Note: Users should get tokens from user OAuth2 endpoint instead
     * @summary Get Access Token
     * @param {TokenCredentialsIn} tokenCredentialsIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessTokenTokenPost: async (
      tokenCredentialsIn: TokenCredentialsIn,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenCredentialsIn' is not null or undefined
      assertParamExists('getAccessTokenTokenPost', 'tokenCredentialsIn', tokenCredentialsIn);
      const localVarPath = `/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tokenCredentialsIn,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the stored floorplan of the location (if any)
     * @summary Get Location Floorplan
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationFloorplanLocationsLocationIdFloorplanGet: async (
      locationId: string | null,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'getLocationFloorplanLocationsLocationIdFloorplanGet',
        'locationId',
        locationId
      );
      const localVarPath = `/locations/{location_id}/floorplan`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Show the current BACnet/IP config for the sensor (if BACnet/IP enabled) and the details of the BACnet/IP gateway the configured for this sensor.
     * @summary Get Sensor Bacnet Config
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSensorBacnetConfigSensorsSensorIdBacnetGet: async (
      sensorId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('getSensorBacnetConfigSensorsSensorIdBacnetGet', 'sensorId', sensorId);
      const localVarPath = `/sensors/{sensor_id}/bacnet`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the sensor shadow config. Only for WiFi/gateway sensors.
     * @summary Get Sensor Shadow Config If Exists
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSensorShadowConfigIfExistsSensorsSensorIdShadowGet: async (
      sensorId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists(
        'getSensorShadowConfigIfExistsSensorsSensorIdShadowGet',
        'sensorId',
        sensorId
      );
      const localVarPath = `/sensors/{sensor_id}/shadow`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
     * @summary Get Visible Subsensors Aps
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVisibleSubsensorsApsSensorsSensorIdVisibleGet: async (
      sensorId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('getVisibleSubsensorsApsSensorsSensorIdVisibleGet', 'sensorId', sensorId);
      const localVarPath = `/sensors/{sensor_id}/visible`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * (SLOW) List which data the user has available
     * @summary List Available Varnames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableVarnamesSensorsDataGet: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/sensors/data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all users in the tree of the given location (requires admin permissions)
     * @summary List Users In Location
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsersInLocationUsersLocationIdAllGet: async (
      locationId: string | null,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('listUsersInLocationUsersLocationIdAllGet', 'locationId', locationId);
      const localVarPath = `/users/{location_id}/all`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start provisioning flow for a new gateway sensor
     * @summary Provision New Gateway Sensor
     * @param {string} sensorId
     * @param {ProvisionSensorIn} provisionSensorIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisionNewGatewaySensorSensorsSensorIdProvisionPost: async (
      sensorId: string,
      provisionSensorIn: ProvisionSensorIn,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists(
        'provisionNewGatewaySensorSensorsSensorIdProvisionPost',
        'sensorId',
        sensorId
      );
      // verify required parameter 'provisionSensorIn' is not null or undefined
      assertParamExists(
        'provisionNewGatewaySensorSensorsSensorIdProvisionPost',
        'provisionSensorIn',
        provisionSensorIn
      );
      const localVarPath = `/sensors/{sensor_id}/provision`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        provisionSensorIn,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the data for a given varName in the sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Latest Data In Location Tree
     * @param {VarName} varName
     * @param {string | null} locationId
     * @param {Array<ProjectData>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet: async (
      varName: VarName,
      locationId: string | null,
      project?: Array<ProjectData>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'varName' is not null or undefined
      assertParamExists(
        'readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet',
        'varName',
        varName
      );
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet',
        'locationId',
        locationId
      );
      const localVarPath = `/locations/{location_id}/all/{var_name}/latest`
        .replace(`{${'var_name'}}`, encodeURIComponent(String(varName)))
        .replace(`{${'location_id'}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the data for a given var_name in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Latest Data In Tree
     * @param {VarName} varName
     * @param {Array<ProjectData>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllLatestDataInTreeSensorsAllVarNameLatestGet: async (
      varName: VarName,
      project?: Array<ProjectData>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'varName' is not null or undefined
      assertParamExists('readAllLatestDataInTreeSensorsAllVarNameLatestGet', 'varName', varName);
      const localVarPath = `/sensors/all/{var_name}/latest`.replace(
        `{${'var_name'}}`,
        encodeURIComponent(String(varName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the sensors in the entire sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Sensors In Location Tree
     * @param {string | null} locationId
     * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet: async (
      locationId: string | null,
      project?: Array<ProjectSensors>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet',
        'locationId',
        locationId
      );
      const localVarPath = `/locations/{location_id}/all/sensors`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the sensors in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Sensors In Tree
     * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllSensorsInTreeSensorsAllGet: async (
      project?: Array<ProjectSensors>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/sensors/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read the locations, sensors and latest sensor data values of the direct children of the root location  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Base Direct Children
     * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBaseDirectChildrenLocationsGet: async (
      project?: Array<ProjectDirectChildren>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/locations/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read the proprties of the user root location.
     * @summary Read Base Location Properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBaseLocationPropertiesLocationsGet: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/locations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Test API access
     * @summary Test API response (Hello World)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readHelloWorldGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the latest proprties and data for a given sensor.  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Latest Sensor Information
     * @param {string} sensorId
     * @param {Array<ProjectSensorLatest>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLatestSensorInformationSensorsSensorIdGet: async (
      sensorId: string,
      project?: Array<ProjectSensorLatest>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('readLatestSensorInformationSensorsSensorIdGet', 'sensorId', sensorId);
      const localVarPath = `/sensors/{sensor_id}`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the daily metric data for sensors that are direct children of a given location for given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. many sensors, long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
     * @summary Read Location Direct Child Daily Data Range
     * @param {VarName} varName
     * @param {string | null} locationId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet: async (
      varName: VarName,
      locationId: string | null,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      project?: Array<DailyMetricProjectTypes>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'varName' is not null or undefined
      assertParamExists(
        'readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet',
        'varName',
        varName
      );
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet',
        'locationId',
        locationId
      );
      // verify required parameter 'dStart' is not null or undefined
      assertParamExists(
        'readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet',
        'dStart',
        dStart
      );
      // verify required parameter 'dEnd' is not null or undefined
      assertParamExists(
        'readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet',
        'dEnd',
        dEnd
      );
      const localVarPath = `/locations/{location_id}/{var_name}/daily`
        .replace(`{${'var_name'}}`, encodeURIComponent(String(varName)))
        .replace(`{${'location_id'}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (dStart !== undefined) {
        localVarQueryParameter['d_start'] =
          (dStart as any) instanceof Date ? (dStart as any).toISOString().substring(0, 10) : dStart;
      }

      if (dEnd !== undefined) {
        localVarQueryParameter['d_end'] =
          (dEnd as any) instanceof Date ? (dEnd as any).toISOString().substring(0, 10) : dEnd;
      }

      if (selectedHours) {
        localVarQueryParameter['selected_hours'] = Array.from(selectedHours);
      }

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read the locations, sensors and latest data values for those that are direct children of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Location Direct Children
     * @param {string | null} locationId
     * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationDirectChildrenLocationsLocationIdGet: async (
      locationId: string | null,
      project?: Array<ProjectDirectChildren>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'readLocationDirectChildrenLocationsLocationIdGet',
        'locationId',
        locationId
      );
      const localVarPath = `/locations/{location_id}/`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read the proprties of the given location.
     * @summary Read Location Properties
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationPropertiesLocationsLocationIdGet: async (
      locationId: string | null,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('readLocationPropertiesLocationsLocationIdGet', 'locationId', locationId);
      const localVarPath = `/locations/{location_id}`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read a summary report of data from all sensors under given location tree for given date range (YYYY-MM-DD), typically for monthly reporting.  (WARNING: big queries i.e. many sensors, long time range may take some time)
     * @summary Read Location Sensor Report
     * @param {string | null} locationId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {boolean} [includeWeekends]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationSensorReportLocationsLocationIdReportGet: async (
      locationId: string | null,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      includeWeekends?: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'readLocationSensorReportLocationsLocationIdReportGet',
        'locationId',
        locationId
      );
      // verify required parameter 'dStart' is not null or undefined
      assertParamExists('readLocationSensorReportLocationsLocationIdReportGet', 'dStart', dStart);
      // verify required parameter 'dEnd' is not null or undefined
      assertParamExists('readLocationSensorReportLocationsLocationIdReportGet', 'dEnd', dEnd);
      const localVarPath = `/locations/{location_id}/report`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (dStart !== undefined) {
        localVarQueryParameter['d_start'] =
          (dStart as any) instanceof Date ? (dStart as any).toISOString().substring(0, 10) : dStart;
      }

      if (dEnd !== undefined) {
        localVarQueryParameter['d_end'] =
          (dEnd as any) instanceof Date ? (dEnd as any).toISOString().substring(0, 10) : dEnd;
      }

      if (selectedHours) {
        localVarQueryParameter['selected_hours'] = Array.from(selectedHours);
      }

      if (includeWeekends !== undefined) {
        localVarQueryParameter['include_weekends'] = includeWeekends;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the locations in the entire sub-tree of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Location Tree
     * @param {string | null} locationId
     * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationTreeLocationsLocationIdAllGet: async (
      locationId: string | null,
      project?: Array<ProjectLocations>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('readLocationTreeLocationsLocationIdAllGet', 'locationId', locationId);
      const localVarPath = `/locations/{location_id}/all`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the locations in the entire sub-tree of the root location (WARNING: big queries i.e. too many locations may be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Root Location Tree
     * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readRootLocationTreeLocationsAllGet: async (
      project?: Array<ProjectLocations>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/locations/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read all the daily metric data for a given sensor for a given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
     * @summary Read Sensor Daily Data Range
     * @param {VarName} varName
     * @param {string} sensorId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet: async (
      varName: VarName,
      sensorId: string,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      project?: Array<DailyMetricProjectTypes>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'varName' is not null or undefined
      assertParamExists(
        'readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet',
        'varName',
        varName
      );
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists(
        'readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet',
        'sensorId',
        sensorId
      );
      // verify required parameter 'dStart' is not null or undefined
      assertParamExists('readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet', 'dStart', dStart);
      // verify required parameter 'dEnd' is not null or undefined
      assertParamExists('readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet', 'dEnd', dEnd);
      const localVarPath = `/sensors/{sensor_id}/{var_name}/daily`
        .replace(`{${'var_name'}}`, encodeURIComponent(String(varName)))
        .replace(`{${'sensor_id'}}`, encodeURIComponent(String(sensorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (dStart !== undefined) {
        localVarQueryParameter['d_start'] =
          (dStart as any) instanceof Date ? (dStart as any).toISOString().substring(0, 10) : dStart;
      }

      if (dEnd !== undefined) {
        localVarQueryParameter['d_end'] =
          (dEnd as any) instanceof Date ? (dEnd as any).toISOString().substring(0, 10) : dEnd;
      }

      if (selectedHours) {
        localVarQueryParameter['selected_hours'] = Array.from(selectedHours);
      }

      if (project) {
        localVarQueryParameter['project'] = project;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Read historic data for a given sensor_id and given data var_name between the specified tstart and tend times, given in seconds since Epoch (Default is the last XX hours)  Example var_names are \'clientsWiFi\' or \'temperatureC\' a good way to find what data is available is to use /sensors/{sensor_id}
     * @summary Read Sensor Historic Data
     * @param {VarName} varName
     * @param {string} sensorId
     * @param {number | null} [tstart]
     * @param {number | null} [tend]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readSensorHistoricDataSensorsSensorIdVarNameHistoryGet: async (
      varName: VarName,
      sensorId: string,
      tstart?: number | null,
      tend?: number | null,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'varName' is not null or undefined
      assertParamExists(
        'readSensorHistoricDataSensorsSensorIdVarNameHistoryGet',
        'varName',
        varName
      );
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists(
        'readSensorHistoricDataSensorsSensorIdVarNameHistoryGet',
        'sensorId',
        sensorId
      );
      const localVarPath = `/sensors/{sensor_id}/{var_name}/history`
        .replace(`{${'var_name'}}`, encodeURIComponent(String(varName)))
        .replace(`{${'sensor_id'}}`, encodeURIComponent(String(sensorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (tstart !== undefined) {
        localVarQueryParameter['tstart'] = tstart;
      }

      if (tend !== undefined) {
        localVarQueryParameter['tend'] = tend;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return access details of current user
     * @summary Read Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readUsersMeUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Disable BACnet/IP for a sensor/gateway. Note: Can only disable gateway from the gateway sensor. Note: After disabling gateway it may take a few minutes for the sensor to become available again.
     * @summary Remove Bacnet Config
     * @param {string} sensorId
     * @param {boolean} areYouSure
     * @param {boolean | null} [removeGateway]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeBacnetConfigSensorsSensorIdBacnetDelete: async (
      sensorId: string,
      areYouSure: boolean,
      removeGateway?: boolean | null,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('removeBacnetConfigSensorsSensorIdBacnetDelete', 'sensorId', sensorId);
      // verify required parameter 'areYouSure' is not null or undefined
      assertParamExists('removeBacnetConfigSensorsSensorIdBacnetDelete', 'areYouSure', areYouSure);
      const localVarPath = `/sensors/{sensor_id}/bacnet`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (areYouSure !== undefined) {
        localVarQueryParameter['are_you_sure'] = areYouSure;
      }

      if (removeGateway !== undefined) {
        localVarQueryParameter['remove_gateway'] = removeGateway;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove a given user from access to the location. (User will still be able to log in for a period of time but have no access to data, unless \'force_delete\' is specified).
     * @summary Remove User Access
     * @param {string} userId
     * @param {string | null} locationId
     * @param {boolean} [forceDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserAccessUsersLocationIdUserIdRemoveDelete: async (
      userId: string,
      locationId: string | null,
      forceDelete?: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('removeUserAccessUsersLocationIdUserIdRemoveDelete', 'userId', userId);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'removeUserAccessUsersLocationIdUserIdRemoveDelete',
        'locationId',
        locationId
      );
      const localVarPath = `/users/{location_id}/{user_id}/remove`
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'location_id'}}`, encodeURIComponent(String(locationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      if (forceDelete !== undefined) {
        localVarQueryParameter['force_delete'] = forceDelete;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Set the stored floorplan of the location, floorplan must be GeoJSON
     * @summary Set Location Floorplan
     * @param {string | null} locationId
     * @param {GeoJSON} geoJSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setLocationFloorplanLocationsLocationIdFloorplanPost: async (
      locationId: string | null,
      geoJSON: GeoJSON,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists(
        'setLocationFloorplanLocationsLocationIdFloorplanPost',
        'locationId',
        locationId
      );
      // verify required parameter 'geoJSON' is not null or undefined
      assertParamExists('setLocationFloorplanLocationsLocationIdFloorplanPost', 'geoJSON', geoJSON);
      const localVarPath = `/locations/{location_id}/floorplan`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        geoJSON,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
     * @summary Update Gateway Config
     * @param {string} sensorId
     * @param {GatewayConfig} gatewayConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGatewayConfigSensorsSensorIdConfigPut: async (
      sensorId: string,
      gatewayConfig: GatewayConfig,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('updateGatewayConfigSensorsSensorIdConfigPut', 'sensorId', sensorId);
      // verify required parameter 'gatewayConfig' is not null or undefined
      assertParamExists(
        'updateGatewayConfigSensorsSensorIdConfigPut',
        'gatewayConfig',
        gatewayConfig
      );
      const localVarPath = `/sensors/{sensor_id}/config`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        gatewayConfig,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the proprties of the given location. (moving location i.e. changing the location attribute to move the tree to a new location, is a work in progress)
     * @summary Update Location Properties
     * @param {string | null} locationId
     * @param {PropLocationItemIn} propLocationItemIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLocationPropertiesLocationsLocationIdPut: async (
      locationId: string | null,
      propLocationItemIn: PropLocationItemIn,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('updateLocationPropertiesLocationsLocationIdPut', 'locationId', locationId);
      // verify required parameter 'propLocationItemIn' is not null or undefined
      assertParamExists(
        'updateLocationPropertiesLocationsLocationIdPut',
        'propLocationItemIn',
        propLocationItemIn
      );
      const localVarPath = `/locations/{location_id}`.replace(
        `{${'location_id'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propLocationItemIn,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update BACnet/IP settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Updating building/vlan network settings may take a few minutes to apply.
     * @summary Update Sensor Bacnet Config
     * @param {string} sensorId
     * @param {BacnetSettingsUpdate} bacnetSettingsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSensorBacnetConfigSensorsSensorIdBacnetPut: async (
      sensorId: string,
      bacnetSettingsUpdate: BacnetSettingsUpdate,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('updateSensorBacnetConfigSensorsSensorIdBacnetPut', 'sensorId', sensorId);
      // verify required parameter 'bacnetSettingsUpdate' is not null or undefined
      assertParamExists(
        'updateSensorBacnetConfigSensorsSensorIdBacnetPut',
        'bacnetSettingsUpdate',
        bacnetSettingsUpdate
      );
      const localVarPath = `/sensors/{sensor_id}/bacnet`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bacnetSettingsUpdate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the properties of the given sensor
     * @summary Update Sensor Properties
     * @param {string} sensorId
     * @param {PropSensorItemIn} propSensorItemIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSensorPropertiesSensorsSensorIdPut: async (
      sensorId: string,
      propSensorItemIn: PropSensorItemIn,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists('updateSensorPropertiesSensorsSensorIdPut', 'sensorId', sensorId);
      // verify required parameter 'propSensorItemIn' is not null or undefined
      assertParamExists(
        'updateSensorPropertiesSensorsSensorIdPut',
        'propSensorItemIn',
        propSensorItemIn
      );
      const localVarPath = `/sensors/{sensor_id}`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        propSensorItemIn,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the sensor shadow config. Only for WiFi/gateway sensors.
     * @summary Update Sensor Shadow Config If Exists
     * @param {string} sensorId
     * @param {ShadowState} shadowState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut: async (
      sensorId: string,
      shadowState: ShadowState,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sensorId' is not null or undefined
      assertParamExists(
        'updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut',
        'sensorId',
        sensorId
      );
      // verify required parameter 'shadowState' is not null or undefined
      assertParamExists(
        'updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut',
        'shadowState',
        shadowState
      );
      const localVarPath = `/sensors/{sensor_id}/shadow`.replace(
        `{${'sensor_id'}}`,
        encodeURIComponent(String(sensorId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication OAuth2AuthorizationCodeBearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'OAuth2AuthorizationCodeBearer',
        [],
        configuration
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shadowState,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * Enable BACnet/IP with defined settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Enabling gateway may take a few minutes to apply.
     * @summary Add Bacnet Config
     * @param {string} sensorId
     * @param {BacnetSettingsCreate} bacnetSettingsCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addBacnetConfigSensorsSensorIdBacnetPost(
      sensorId: string,
      bacnetSettingsCreate: BacnetSettingsCreate,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BacnetSettingsCreate>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addBacnetConfigSensorsSensorIdBacnetPost(
          sensorId,
          bacnetSettingsCreate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.addBacnetConfigSensorsSensorIdBacnetPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add an entry to the DB for a new sensor (subsensors only supported)
     * @summary Add New Sensor
     * @param {string} sensorId
     * @param {PropSensorItemCreate} propSensorItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addNewSensorSensorsSensorIdPost(
      sensorId: string,
      propSensorItemCreate: PropSensorItemCreate,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropSensorItem>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addNewSensorSensorsSensorIdPost(
        sensorId,
        propSensorItemCreate,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.addNewSensorSensorsSensorIdPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Add a user to the location using their email address, sends an invite email, requires admin permissions for the given location.  May also be used to change an exsting user in the location tree of the admin.
     * @summary Add New User To Location
     * @param {string | null} locationId
     * @param {NewUserDetails} newUserDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addNewUserToLocationUsersLocationIdAddPost(
      locationId: string | null,
      newUserDetails: NewUserDetails,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | null }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addNewUserToLocationUsersLocationIdAddPost(
          locationId,
          newUserDetails,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.addNewUserToLocationUsersLocationIdAddPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create a new location in the database. Sensors can then be placed in this location.
     * @summary Create New Location
     * @param {string | null} locationId
     * @param {PropLocationItemCreate} propLocationItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNewLocationLocationsLocationIdPost(
      locationId: string | null,
      propLocationItemCreate: PropLocationItemCreate,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropLocationItem>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNewLocationLocationsLocationIdPost(
          locationId,
          propLocationItemCreate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.createNewLocationLocationsLocationIdPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete the stored floorplan of the location
     * @summary Delete Location Floorplan
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLocationFloorplanLocationsLocationIdFloorplanDelete(
      locationId: string | null,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteLocationFloorplanLocationsLocationIdFloorplanDelete(
          locationId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultApi.deleteLocationFloorplanLocationsLocationIdFloorplanDelete'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a location, location must be empty (i.e. location must have no child locations, sensors etc.)
     * @summary Delete Location
     * @param {string | null} locationId
     * @param {boolean} [areYouSure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLocationLocationsLocationIdDelete(
      locationId: string | null,
      areYouSure?: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteLocationLocationsLocationIdDelete(
          locationId,
          areYouSure,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.deleteLocationLocationsLocationIdDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete the given sensor (SubSensor only currently, must be disabled first)
     * @summary Delete Sensor
     * @param {string} sensorId
     * @param {boolean} [areYouSure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteSensorSensorsSensorIdDelete(
      sensorId: string,
      areYouSure?: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSensorSensorsSensorIdDelete(
        sensorId,
        areYouSure,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.deleteSensorSensorsSensorIdDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Generate API access token from temporary/API credentials Note: Users should get tokens from user OAuth2 endpoint instead
     * @summary Get Access Token
     * @param {TokenCredentialsIn} tokenCredentialsIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccessTokenTokenPost(
      tokenCredentialsIn: TokenCredentialsIn,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessTokenTokenPost(
        tokenCredentialsIn,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getAccessTokenTokenPost']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the stored floorplan of the location (if any)
     * @summary Get Location Floorplan
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationFloorplanLocationsLocationIdFloorplanGet(
      locationId: string | null,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeoJSON>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLocationFloorplanLocationsLocationIdFloorplanGet(
          locationId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getLocationFloorplanLocationsLocationIdFloorplanGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Show the current BACnet/IP config for the sensor (if BACnet/IP enabled) and the details of the BACnet/IP gateway the configured for this sensor.
     * @summary Get Sensor Bacnet Config
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSensorBacnetConfigSensorsSensorIdBacnetGet(
      sensorId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BacnetSettings>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSensorBacnetConfigSensorsSensorIdBacnetGet(
          sensorId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getSensorBacnetConfigSensorsSensorIdBacnetGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get the sensor shadow config. Only for WiFi/gateway sensors.
     * @summary Get Sensor Shadow Config If Exists
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSensorShadowConfigIfExistsSensorsSensorIdShadowGet(
      sensorId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getSensorShadowConfigIfExistsSensorsSensorIdShadowGet(
          sensorId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getSensorShadowConfigIfExistsSensorsSensorIdShadowGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
     * @summary Get Visible Subsensors Aps
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVisibleSubsensorsApsSensorsSensorIdVisibleGet(
      sensorId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisibleItems>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getVisibleSubsensorsApsSensorsSensorIdVisibleGet(
          sensorId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.getVisibleSubsensorsApsSensorsSensorIdVisibleGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * (SLOW) List which data the user has available
     * @summary List Available Varnames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAvailableVarnamesSensorsDataGet(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VarName>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailableVarnamesSensorsDataGet(
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.listAvailableVarnamesSensorsDataGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * List all users in the tree of the given location (requires admin permissions)
     * @summary List Users In Location
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listUsersInLocationUsersLocationIdAllGet(
      locationId: string | null,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListOut>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.listUsersInLocationUsersLocationIdAllGet(
          locationId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.listUsersInLocationUsersLocationIdAllGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Start provisioning flow for a new gateway sensor
     * @summary Provision New Gateway Sensor
     * @param {string} sensorId
     * @param {ProvisionSensorIn} provisionSensorIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async provisionNewGatewaySensorSensorsSensorIdProvisionPost(
      sensorId: string,
      provisionSensorIn: ProvisionSensorIn,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvisionSensorClaimOut>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.provisionNewGatewaySensorSensorsSensorIdProvisionPost(
          sensorId,
          provisionSensorIn,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.provisionNewGatewaySensorSensorsSensorIdProvisionPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the data for a given varName in the sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Latest Data In Location Tree
     * @param {VarName} varName
     * @param {string | null} locationId
     * @param {Array<ProjectData>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet(
      varName: VarName,
      locationId: string | null,
      project?: Array<ProjectData>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTreeItems>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet(
          varName,
          locationId,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultApi.readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the data for a given var_name in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Latest Data In Tree
     * @param {VarName} varName
     * @param {Array<ProjectData>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readAllLatestDataInTreeSensorsAllVarNameLatestGet(
      varName: VarName,
      project?: Array<ProjectData>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataTreeItems>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readAllLatestDataInTreeSensorsAllVarNameLatestGet(
          varName,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readAllLatestDataInTreeSensorsAllVarNameLatestGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the sensors in the entire sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Sensors In Location Tree
     * @param {string | null} locationId
     * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(
      locationId: string | null,
      project?: Array<ProjectSensors>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorTreeItems>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(
          locationId,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultApi.readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the sensors in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Sensors In Tree
     * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readAllSensorsInTreeSensorsAllGet(
      project?: Array<ProjectSensors>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorTreeItems>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readAllSensorsInTreeSensorsAllGet(
        project,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readAllSensorsInTreeSensorsAllGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read the locations, sensors and latest sensor data values of the direct children of the root location  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Base Direct Children
     * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readBaseDirectChildrenLocationsGet(
      project?: Array<ProjectDirectChildren>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDirectChildren>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readBaseDirectChildrenLocationsGet(
        project,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readBaseDirectChildrenLocationsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read the proprties of the user root location.
     * @summary Read Base Location Properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readBaseLocationPropertiesLocationsGet(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropLocationItem>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readBaseLocationPropertiesLocationsGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readBaseLocationPropertiesLocationsGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Test API access
     * @summary Test API response (Hello World)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readHelloWorldGet(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readHelloWorldGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readHelloWorldGet']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the latest proprties and data for a given sensor.  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Latest Sensor Information
     * @param {string} sensorId
     * @param {Array<ProjectSensorLatest>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readLatestSensorInformationSensorsSensorIdGet(
      sensorId: string,
      project?: Array<ProjectSensorLatest>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorLatest>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readLatestSensorInformationSensorsSensorIdGet(
          sensorId,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readLatestSensorInformationSensorsSensorIdGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the daily metric data for sensors that are direct children of a given location for given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. many sensors, long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
     * @summary Read Location Direct Child Daily Data Range
     * @param {VarName} varName
     * @param {string | null} locationId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
      varName: VarName,
      locationId: string | null,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      project?: Array<DailyMetricProjectTypes>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyMetricItem>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
          varName,
          locationId,
          dStart,
          dEnd,
          selectedHours,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap[
          'DefaultApi.readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet'
        ]?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read the locations, sensors and latest data values for those that are direct children of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Location Direct Children
     * @param {string | null} locationId
     * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readLocationDirectChildrenLocationsLocationIdGet(
      locationId: string | null,
      project?: Array<ProjectDirectChildren>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationDirectChildren>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readLocationDirectChildrenLocationsLocationIdGet(
          locationId,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readLocationDirectChildrenLocationsLocationIdGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read the proprties of the given location.
     * @summary Read Location Properties
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readLocationPropertiesLocationsLocationIdGet(
      locationId: string | null,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropLocationItem>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readLocationPropertiesLocationsLocationIdGet(
          locationId,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readLocationPropertiesLocationsLocationIdGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read a summary report of data from all sensors under given location tree for given date range (YYYY-MM-DD), typically for monthly reporting.  (WARNING: big queries i.e. many sensors, long time range may take some time)
     * @summary Read Location Sensor Report
     * @param {string | null} locationId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {boolean} [includeWeekends]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readLocationSensorReportLocationsLocationIdReportGet(
      locationId: string | null,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      includeWeekends?: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportOutput>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readLocationSensorReportLocationsLocationIdReportGet(
          locationId,
          dStart,
          dEnd,
          selectedHours,
          includeWeekends,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readLocationSensorReportLocationsLocationIdReportGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the locations in the entire sub-tree of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Location Tree
     * @param {string | null} locationId
     * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readLocationTreeLocationsLocationIdAllGet(
      locationId: string | null,
      project?: Array<ProjectLocations>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationTreeItems>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readLocationTreeLocationsLocationIdAllGet(
          locationId,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readLocationTreeLocationsLocationIdAllGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the locations in the entire sub-tree of the root location (WARNING: big queries i.e. too many locations may be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Root Location Tree
     * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readRootLocationTreeLocationsAllGet(
      project?: Array<ProjectLocations>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationTreeItems>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readRootLocationTreeLocationsAllGet(
        project,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readRootLocationTreeLocationsAllGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read all the daily metric data for a given sensor for a given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
     * @summary Read Sensor Daily Data Range
     * @param {VarName} varName
     * @param {string} sensorId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
      varName: VarName,
      sensorId: string,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      project?: Array<DailyMetricProjectTypes>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DailyMetricItem>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
          varName,
          sensorId,
          dStart,
          dEnd,
          selectedHours,
          project,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Read historic data for a given sensor_id and given data var_name between the specified tstart and tend times, given in seconds since Epoch (Default is the last XX hours)  Example var_names are \'clientsWiFi\' or \'temperatureC\' a good way to find what data is available is to use /sensors/{sensor_id}
     * @summary Read Sensor Historic Data
     * @param {VarName} varName
     * @param {string} sensorId
     * @param {number | null} [tstart]
     * @param {number | null} [tend]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(
      varName: VarName,
      sensorId: string,
      tstart?: number | null,
      tend?: number | null,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoricData>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(
          varName,
          sensorId,
          tstart,
          tend,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readSensorHistoricDataSensorsSensorIdVarNameHistoryGet']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Return access details of current user
     * @summary Read Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readUsersMeUsersMeGet(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublic>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersMeUsersMeGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readUsersMeUsersMeGet']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Disable BACnet/IP for a sensor/gateway. Note: Can only disable gateway from the gateway sensor. Note: After disabling gateway it may take a few minutes for the sensor to become available again.
     * @summary Remove Bacnet Config
     * @param {string} sensorId
     * @param {boolean} areYouSure
     * @param {boolean | null} [removeGateway]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeBacnetConfigSensorsSensorIdBacnetDelete(
      sensorId: string,
      areYouSure: boolean,
      removeGateway?: boolean | null,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeBacnetConfigSensorsSensorIdBacnetDelete(
          sensorId,
          areYouSure,
          removeGateway,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.removeBacnetConfigSensorsSensorIdBacnetDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Remove a given user from access to the location. (User will still be able to log in for a period of time but have no access to data, unless \'force_delete\' is specified).
     * @summary Remove User Access
     * @param {string} userId
     * @param {string | null} locationId
     * @param {boolean} [forceDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeUserAccessUsersLocationIdUserIdRemoveDelete(
      userId: string,
      locationId: string | null,
      forceDelete?: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string | null }>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeUserAccessUsersLocationIdUserIdRemoveDelete(
          userId,
          locationId,
          forceDelete,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.removeUserAccessUsersLocationIdUserIdRemoveDelete']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Set the stored floorplan of the location, floorplan must be GeoJSON
     * @summary Set Location Floorplan
     * @param {string | null} locationId
     * @param {GeoJSON} geoJSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setLocationFloorplanLocationsLocationIdFloorplanPost(
      locationId: string | null,
      geoJSON: GeoJSON,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setLocationFloorplanLocationsLocationIdFloorplanPost(
          locationId,
          geoJSON,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.setLocationFloorplanLocationsLocationIdFloorplanPost']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
     * @summary Update Gateway Config
     * @param {string} sensorId
     * @param {GatewayConfig} gatewayConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGatewayConfigSensorsSensorIdConfigPut(
      sensorId: string,
      gatewayConfig: GatewayConfig,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GatewayConfig>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateGatewayConfigSensorsSensorIdConfigPut(
          sensorId,
          gatewayConfig,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.updateGatewayConfigSensorsSensorIdConfigPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update the proprties of the given location. (moving location i.e. changing the location attribute to move the tree to a new location, is a work in progress)
     * @summary Update Location Properties
     * @param {string | null} locationId
     * @param {PropLocationItemIn} propLocationItemIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLocationPropertiesLocationsLocationIdPut(
      locationId: string | null,
      propLocationItemIn: PropLocationItemIn,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropLocationItem>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateLocationPropertiesLocationsLocationIdPut(
          locationId,
          propLocationItemIn,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.updateLocationPropertiesLocationsLocationIdPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update BACnet/IP settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Updating building/vlan network settings may take a few minutes to apply.
     * @summary Update Sensor Bacnet Config
     * @param {string} sensorId
     * @param {BacnetSettingsUpdate} bacnetSettingsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSensorBacnetConfigSensorsSensorIdBacnetPut(
      sensorId: string,
      bacnetSettingsUpdate: BacnetSettingsUpdate,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BacnetSettingsUpdate>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSensorBacnetConfigSensorsSensorIdBacnetPut(
          sensorId,
          bacnetSettingsUpdate,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.updateSensorBacnetConfigSensorsSensorIdBacnetPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update the properties of the given sensor
     * @summary Update Sensor Properties
     * @param {string} sensorId
     * @param {PropSensorItemIn} propSensorItemIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSensorPropertiesSensorsSensorIdPut(
      sensorId: string,
      propSensorItemIn: PropSensorItemIn,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropSensorItem>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSensorPropertiesSensorsSensorIdPut(
          sensorId,
          propSensorItemIn,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.updateSensorPropertiesSensorsSensorIdPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update the sensor shadow config. Only for WiFi/gateway sensors.
     * @summary Update Sensor Shadow Config If Exists
     * @param {string} sensorId
     * @param {ShadowState} shadowState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut(
      sensorId: string,
      shadowState: ShadowState,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut(
          sensorId,
          shadowState,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * Enable BACnet/IP with defined settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Enabling gateway may take a few minutes to apply.
     * @summary Add Bacnet Config
     * @param {string} sensorId
     * @param {BacnetSettingsCreate} bacnetSettingsCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addBacnetConfigSensorsSensorIdBacnetPost(
      sensorId: string,
      bacnetSettingsCreate: BacnetSettingsCreate,
      options?: any
    ): AxiosPromise<BacnetSettingsCreate> {
      return localVarFp
        .addBacnetConfigSensorsSensorIdBacnetPost(sensorId, bacnetSettingsCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add an entry to the DB for a new sensor (subsensors only supported)
     * @summary Add New Sensor
     * @param {string} sensorId
     * @param {PropSensorItemCreate} propSensorItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNewSensorSensorsSensorIdPost(
      sensorId: string,
      propSensorItemCreate: PropSensorItemCreate,
      options?: any
    ): AxiosPromise<PropSensorItem> {
      return localVarFp
        .addNewSensorSensorsSensorIdPost(sensorId, propSensorItemCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add a user to the location using their email address, sends an invite email, requires admin permissions for the given location.  May also be used to change an exsting user in the location tree of the admin.
     * @summary Add New User To Location
     * @param {string | null} locationId
     * @param {NewUserDetails} newUserDetails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNewUserToLocationUsersLocationIdAddPost(
      locationId: string | null,
      newUserDetails: NewUserDetails,
      options?: any
    ): AxiosPromise<{ [key: string]: string | null }> {
      return localVarFp
        .addNewUserToLocationUsersLocationIdAddPost(locationId, newUserDetails, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new location in the database. Sensors can then be placed in this location.
     * @summary Create New Location
     * @param {string | null} locationId
     * @param {PropLocationItemCreate} propLocationItemCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNewLocationLocationsLocationIdPost(
      locationId: string | null,
      propLocationItemCreate: PropLocationItemCreate,
      options?: any
    ): AxiosPromise<PropLocationItem> {
      return localVarFp
        .createNewLocationLocationsLocationIdPost(locationId, propLocationItemCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete the stored floorplan of the location
     * @summary Delete Location Floorplan
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLocationFloorplanLocationsLocationIdFloorplanDelete(
      locationId: string | null,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteLocationFloorplanLocationsLocationIdFloorplanDelete(locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a location, location must be empty (i.e. location must have no child locations, sensors etc.)
     * @summary Delete Location
     * @param {string | null} locationId
     * @param {boolean} [areYouSure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLocationLocationsLocationIdDelete(
      locationId: string | null,
      areYouSure?: boolean,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteLocationLocationsLocationIdDelete(locationId, areYouSure, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete the given sensor (SubSensor only currently, must be disabled first)
     * @summary Delete Sensor
     * @param {string} sensorId
     * @param {boolean} [areYouSure]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSensorSensorsSensorIdDelete(
      sensorId: string,
      areYouSure?: boolean,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteSensorSensorsSensorIdDelete(sensorId, areYouSure, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Generate API access token from temporary/API credentials Note: Users should get tokens from user OAuth2 endpoint instead
     * @summary Get Access Token
     * @param {TokenCredentialsIn} tokenCredentialsIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccessTokenTokenPost(
      tokenCredentialsIn: TokenCredentialsIn,
      options?: any
    ): AxiosPromise<TokenOutput> {
      return localVarFp
        .getAccessTokenTokenPost(tokenCredentialsIn, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the stored floorplan of the location (if any)
     * @summary Get Location Floorplan
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationFloorplanLocationsLocationIdFloorplanGet(
      locationId: string | null,
      options?: any
    ): AxiosPromise<GeoJSON> {
      return localVarFp
        .getLocationFloorplanLocationsLocationIdFloorplanGet(locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Show the current BACnet/IP config for the sensor (if BACnet/IP enabled) and the details of the BACnet/IP gateway the configured for this sensor.
     * @summary Get Sensor Bacnet Config
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSensorBacnetConfigSensorsSensorIdBacnetGet(
      sensorId: string,
      options?: any
    ): AxiosPromise<BacnetSettings> {
      return localVarFp
        .getSensorBacnetConfigSensorsSensorIdBacnetGet(sensorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the sensor shadow config. Only for WiFi/gateway sensors.
     * @summary Get Sensor Shadow Config If Exists
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSensorShadowConfigIfExistsSensorsSensorIdShadowGet(
      sensorId: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .getSensorShadowConfigIfExistsSensorsSensorIdShadowGet(sensorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
     * @summary Get Visible Subsensors Aps
     * @param {string} sensorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVisibleSubsensorsApsSensorsSensorIdVisibleGet(
      sensorId: string,
      options?: any
    ): AxiosPromise<VisibleItems> {
      return localVarFp
        .getVisibleSubsensorsApsSensorsSensorIdVisibleGet(sensorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * (SLOW) List which data the user has available
     * @summary List Available Varnames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAvailableVarnamesSensorsDataGet(options?: any): AxiosPromise<Array<VarName>> {
      return localVarFp
        .listAvailableVarnamesSensorsDataGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List all users in the tree of the given location (requires admin permissions)
     * @summary List Users In Location
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsersInLocationUsersLocationIdAllGet(
      locationId: string | null,
      options?: any
    ): AxiosPromise<UserListOut> {
      return localVarFp
        .listUsersInLocationUsersLocationIdAllGet(locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Start provisioning flow for a new gateway sensor
     * @summary Provision New Gateway Sensor
     * @param {string} sensorId
     * @param {ProvisionSensorIn} provisionSensorIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    provisionNewGatewaySensorSensorsSensorIdProvisionPost(
      sensorId: string,
      provisionSensorIn: ProvisionSensorIn,
      options?: any
    ): AxiosPromise<ProvisionSensorClaimOut> {
      return localVarFp
        .provisionNewGatewaySensorSensorsSensorIdProvisionPost(sensorId, provisionSensorIn, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the data for a given varName in the sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Latest Data In Location Tree
     * @param {VarName} varName
     * @param {string | null} locationId
     * @param {Array<ProjectData>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet(
      varName: VarName,
      locationId: string | null,
      project?: Array<ProjectData>,
      options?: any
    ): AxiosPromise<DataTreeItems> {
      return localVarFp
        .readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet(
          varName,
          locationId,
          project,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the data for a given var_name in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Latest Data In Tree
     * @param {VarName} varName
     * @param {Array<ProjectData>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllLatestDataInTreeSensorsAllVarNameLatestGet(
      varName: VarName,
      project?: Array<ProjectData>,
      options?: any
    ): AxiosPromise<DataTreeItems> {
      return localVarFp
        .readAllLatestDataInTreeSensorsAllVarNameLatestGet(varName, project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the sensors in the entire sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Sensors In Location Tree
     * @param {string | null} locationId
     * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(
      locationId: string | null,
      project?: Array<ProjectSensors>,
      options?: any
    ): AxiosPromise<SensorTreeItems> {
      return localVarFp
        .readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(locationId, project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the sensors in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read All Sensors In Tree
     * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readAllSensorsInTreeSensorsAllGet(
      project?: Array<ProjectSensors>,
      options?: any
    ): AxiosPromise<SensorTreeItems> {
      return localVarFp
        .readAllSensorsInTreeSensorsAllGet(project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read the locations, sensors and latest sensor data values of the direct children of the root location  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Base Direct Children
     * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBaseDirectChildrenLocationsGet(
      project?: Array<ProjectDirectChildren>,
      options?: any
    ): AxiosPromise<LocationDirectChildren> {
      return localVarFp
        .readBaseDirectChildrenLocationsGet(project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read the proprties of the user root location.
     * @summary Read Base Location Properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readBaseLocationPropertiesLocationsGet(options?: any): AxiosPromise<PropLocationItem> {
      return localVarFp
        .readBaseLocationPropertiesLocationsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Test API access
     * @summary Test API response (Hello World)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readHelloWorldGet(options?: any): AxiosPromise<any> {
      return localVarFp.readHelloWorldGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Read all the latest proprties and data for a given sensor.  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Latest Sensor Information
     * @param {string} sensorId
     * @param {Array<ProjectSensorLatest>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLatestSensorInformationSensorsSensorIdGet(
      sensorId: string,
      project?: Array<ProjectSensorLatest>,
      options?: any
    ): AxiosPromise<SensorLatest> {
      return localVarFp
        .readLatestSensorInformationSensorsSensorIdGet(sensorId, project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the daily metric data for sensors that are direct children of a given location for given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. many sensors, long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
     * @summary Read Location Direct Child Daily Data Range
     * @param {VarName} varName
     * @param {string | null} locationId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
      varName: VarName,
      locationId: string | null,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      project?: Array<DailyMetricProjectTypes>,
      options?: any
    ): AxiosPromise<Array<DailyMetricItem>> {
      return localVarFp
        .readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
          varName,
          locationId,
          dStart,
          dEnd,
          selectedHours,
          project,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Read the locations, sensors and latest data values for those that are direct children of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Location Direct Children
     * @param {string | null} locationId
     * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationDirectChildrenLocationsLocationIdGet(
      locationId: string | null,
      project?: Array<ProjectDirectChildren>,
      options?: any
    ): AxiosPromise<LocationDirectChildren> {
      return localVarFp
        .readLocationDirectChildrenLocationsLocationIdGet(locationId, project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read the proprties of the given location.
     * @summary Read Location Properties
     * @param {string | null} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationPropertiesLocationsLocationIdGet(
      locationId: string | null,
      options?: any
    ): AxiosPromise<PropLocationItem> {
      return localVarFp
        .readLocationPropertiesLocationsLocationIdGet(locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read a summary report of data from all sensors under given location tree for given date range (YYYY-MM-DD), typically for monthly reporting.  (WARNING: big queries i.e. many sensors, long time range may take some time)
     * @summary Read Location Sensor Report
     * @param {string | null} locationId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {boolean} [includeWeekends]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationSensorReportLocationsLocationIdReportGet(
      locationId: string | null,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      includeWeekends?: boolean,
      options?: any
    ): AxiosPromise<ReportOutput> {
      return localVarFp
        .readLocationSensorReportLocationsLocationIdReportGet(
          locationId,
          dStart,
          dEnd,
          selectedHours,
          includeWeekends,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the locations in the entire sub-tree of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Location Tree
     * @param {string | null} locationId
     * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readLocationTreeLocationsLocationIdAllGet(
      locationId: string | null,
      project?: Array<ProjectLocations>,
      options?: any
    ): AxiosPromise<LocationTreeItems> {
      return localVarFp
        .readLocationTreeLocationsLocationIdAllGet(locationId, project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the locations in the entire sub-tree of the root location (WARNING: big queries i.e. too many locations may be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
     * @summary Read Root Location Tree
     * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readRootLocationTreeLocationsAllGet(
      project?: Array<ProjectLocations>,
      options?: any
    ): AxiosPromise<LocationTreeItems> {
      return localVarFp
        .readRootLocationTreeLocationsAllGet(project, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Read all the daily metric data for a given sensor for a given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
     * @summary Read Sensor Daily Data Range
     * @param {VarName} varName
     * @param {string} sensorId
     * @param {string} dStart
     * @param {string} dEnd
     * @param {Set<number>} [selectedHours]
     * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
      varName: VarName,
      sensorId: string,
      dStart: string,
      dEnd: string,
      selectedHours?: Set<number>,
      project?: Array<DailyMetricProjectTypes>,
      options?: any
    ): AxiosPromise<Array<DailyMetricItem>> {
      return localVarFp
        .readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
          varName,
          sensorId,
          dStart,
          dEnd,
          selectedHours,
          project,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Read historic data for a given sensor_id and given data var_name between the specified tstart and tend times, given in seconds since Epoch (Default is the last XX hours)  Example var_names are \'clientsWiFi\' or \'temperatureC\' a good way to find what data is available is to use /sensors/{sensor_id}
     * @summary Read Sensor Historic Data
     * @param {VarName} varName
     * @param {string} sensorId
     * @param {number | null} [tstart]
     * @param {number | null} [tend]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(
      varName: VarName,
      sensorId: string,
      tstart?: number | null,
      tend?: number | null,
      options?: any
    ): AxiosPromise<HistoricData> {
      return localVarFp
        .readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(
          varName,
          sensorId,
          tstart,
          tend,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Return access details of current user
     * @summary Read Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readUsersMeUsersMeGet(options?: any): AxiosPromise<UserPublic> {
      return localVarFp.readUsersMeUsersMeGet(options).then((request) => request(axios, basePath));
    },
    /**
     * Disable BACnet/IP for a sensor/gateway. Note: Can only disable gateway from the gateway sensor. Note: After disabling gateway it may take a few minutes for the sensor to become available again.
     * @summary Remove Bacnet Config
     * @param {string} sensorId
     * @param {boolean} areYouSure
     * @param {boolean | null} [removeGateway]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeBacnetConfigSensorsSensorIdBacnetDelete(
      sensorId: string,
      areYouSure: boolean,
      removeGateway?: boolean | null,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .removeBacnetConfigSensorsSensorIdBacnetDelete(sensorId, areYouSure, removeGateway, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove a given user from access to the location. (User will still be able to log in for a period of time but have no access to data, unless \'force_delete\' is specified).
     * @summary Remove User Access
     * @param {string} userId
     * @param {string | null} locationId
     * @param {boolean} [forceDelete]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeUserAccessUsersLocationIdUserIdRemoveDelete(
      userId: string,
      locationId: string | null,
      forceDelete?: boolean,
      options?: any
    ): AxiosPromise<{ [key: string]: string | null }> {
      return localVarFp
        .removeUserAccessUsersLocationIdUserIdRemoveDelete(userId, locationId, forceDelete, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Set the stored floorplan of the location, floorplan must be GeoJSON
     * @summary Set Location Floorplan
     * @param {string | null} locationId
     * @param {GeoJSON} geoJSON
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setLocationFloorplanLocationsLocationIdFloorplanPost(
      locationId: string | null,
      geoJSON: GeoJSON,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .setLocationFloorplanLocationsLocationIdFloorplanPost(locationId, geoJSON, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
     * @summary Update Gateway Config
     * @param {string} sensorId
     * @param {GatewayConfig} gatewayConfig
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGatewayConfigSensorsSensorIdConfigPut(
      sensorId: string,
      gatewayConfig: GatewayConfig,
      options?: any
    ): AxiosPromise<GatewayConfig> {
      return localVarFp
        .updateGatewayConfigSensorsSensorIdConfigPut(sensorId, gatewayConfig, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the proprties of the given location. (moving location i.e. changing the location attribute to move the tree to a new location, is a work in progress)
     * @summary Update Location Properties
     * @param {string | null} locationId
     * @param {PropLocationItemIn} propLocationItemIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLocationPropertiesLocationsLocationIdPut(
      locationId: string | null,
      propLocationItemIn: PropLocationItemIn,
      options?: any
    ): AxiosPromise<PropLocationItem> {
      return localVarFp
        .updateLocationPropertiesLocationsLocationIdPut(locationId, propLocationItemIn, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update BACnet/IP settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Updating building/vlan network settings may take a few minutes to apply.
     * @summary Update Sensor Bacnet Config
     * @param {string} sensorId
     * @param {BacnetSettingsUpdate} bacnetSettingsUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSensorBacnetConfigSensorsSensorIdBacnetPut(
      sensorId: string,
      bacnetSettingsUpdate: BacnetSettingsUpdate,
      options?: any
    ): AxiosPromise<BacnetSettingsUpdate> {
      return localVarFp
        .updateSensorBacnetConfigSensorsSensorIdBacnetPut(sensorId, bacnetSettingsUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the properties of the given sensor
     * @summary Update Sensor Properties
     * @param {string} sensorId
     * @param {PropSensorItemIn} propSensorItemIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSensorPropertiesSensorsSensorIdPut(
      sensorId: string,
      propSensorItemIn: PropSensorItemIn,
      options?: any
    ): AxiosPromise<PropSensorItem> {
      return localVarFp
        .updateSensorPropertiesSensorsSensorIdPut(sensorId, propSensorItemIn, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the sensor shadow config. Only for WiFi/gateway sensors.
     * @summary Update Sensor Shadow Config If Exists
     * @param {string} sensorId
     * @param {ShadowState} shadowState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut(
      sensorId: string,
      shadowState: ShadowState,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut(sensorId, shadowState, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Enable BACnet/IP with defined settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Enabling gateway may take a few minutes to apply.
   * @summary Add Bacnet Config
   * @param {string} sensorId
   * @param {BacnetSettingsCreate} bacnetSettingsCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public addBacnetConfigSensorsSensorIdBacnetPost(
    sensorId: string,
    bacnetSettingsCreate: BacnetSettingsCreate,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .addBacnetConfigSensorsSensorIdBacnetPost(sensorId, bacnetSettingsCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add an entry to the DB for a new sensor (subsensors only supported)
   * @summary Add New Sensor
   * @param {string} sensorId
   * @param {PropSensorItemCreate} propSensorItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public addNewSensorSensorsSensorIdPost(
    sensorId: string,
    propSensorItemCreate: PropSensorItemCreate,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .addNewSensorSensorsSensorIdPost(sensorId, propSensorItemCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add a user to the location using their email address, sends an invite email, requires admin permissions for the given location.  May also be used to change an exsting user in the location tree of the admin.
   * @summary Add New User To Location
   * @param {string | null} locationId
   * @param {NewUserDetails} newUserDetails
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public addNewUserToLocationUsersLocationIdAddPost(
    locationId: string | null,
    newUserDetails: NewUserDetails,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .addNewUserToLocationUsersLocationIdAddPost(locationId, newUserDetails, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new location in the database. Sensors can then be placed in this location.
   * @summary Create New Location
   * @param {string | null} locationId
   * @param {PropLocationItemCreate} propLocationItemCreate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createNewLocationLocationsLocationIdPost(
    locationId: string | null,
    propLocationItemCreate: PropLocationItemCreate,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .createNewLocationLocationsLocationIdPost(locationId, propLocationItemCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete the stored floorplan of the location
   * @summary Delete Location Floorplan
   * @param {string | null} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteLocationFloorplanLocationsLocationIdFloorplanDelete(
    locationId: string | null,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .deleteLocationFloorplanLocationsLocationIdFloorplanDelete(locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a location, location must be empty (i.e. location must have no child locations, sensors etc.)
   * @summary Delete Location
   * @param {string | null} locationId
   * @param {boolean} [areYouSure]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteLocationLocationsLocationIdDelete(
    locationId: string | null,
    areYouSure?: boolean,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .deleteLocationLocationsLocationIdDelete(locationId, areYouSure, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete the given sensor (SubSensor only currently, must be disabled first)
   * @summary Delete Sensor
   * @param {string} sensorId
   * @param {boolean} [areYouSure]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteSensorSensorsSensorIdDelete(
    sensorId: string,
    areYouSure?: boolean,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .deleteSensorSensorsSensorIdDelete(sensorId, areYouSure, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Generate API access token from temporary/API credentials Note: Users should get tokens from user OAuth2 endpoint instead
   * @summary Get Access Token
   * @param {TokenCredentialsIn} tokenCredentialsIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAccessTokenTokenPost(
    tokenCredentialsIn: TokenCredentialsIn,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .getAccessTokenTokenPost(tokenCredentialsIn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the stored floorplan of the location (if any)
   * @summary Get Location Floorplan
   * @param {string | null} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getLocationFloorplanLocationsLocationIdFloorplanGet(
    locationId: string | null,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .getLocationFloorplanLocationsLocationIdFloorplanGet(locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Show the current BACnet/IP config for the sensor (if BACnet/IP enabled) and the details of the BACnet/IP gateway the configured for this sensor.
   * @summary Get Sensor Bacnet Config
   * @param {string} sensorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getSensorBacnetConfigSensorsSensorIdBacnetGet(
    sensorId: string,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .getSensorBacnetConfigSensorsSensorIdBacnetGet(sensorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the sensor shadow config. Only for WiFi/gateway sensors.
   * @summary Get Sensor Shadow Config If Exists
   * @param {string} sensorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getSensorShadowConfigIfExistsSensorsSensorIdShadowGet(
    sensorId: string,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .getSensorShadowConfigIfExistsSensorsSensorIdShadowGet(sensorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
   * @summary Get Visible Subsensors Aps
   * @param {string} sensorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getVisibleSubsensorsApsSensorsSensorIdVisibleGet(
    sensorId: string,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .getVisibleSubsensorsApsSensorsSensorIdVisibleGet(sensorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * (SLOW) List which data the user has available
   * @summary List Available Varnames
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listAvailableVarnamesSensorsDataGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listAvailableVarnamesSensorsDataGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List all users in the tree of the given location (requires admin permissions)
   * @summary List Users In Location
   * @param {string | null} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listUsersInLocationUsersLocationIdAllGet(
    locationId: string | null,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .listUsersInLocationUsersLocationIdAllGet(locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Start provisioning flow for a new gateway sensor
   * @summary Provision New Gateway Sensor
   * @param {string} sensorId
   * @param {ProvisionSensorIn} provisionSensorIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public provisionNewGatewaySensorSensorsSensorIdProvisionPost(
    sensorId: string,
    provisionSensorIn: ProvisionSensorIn,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .provisionNewGatewaySensorSensorsSensorIdProvisionPost(sensorId, provisionSensorIn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the data for a given varName in the sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read All Latest Data In Location Tree
   * @param {VarName} varName
   * @param {string | null} locationId
   * @param {Array<ProjectData>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet(
    varName: VarName,
    locationId: string | null,
    project?: Array<ProjectData>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readAllLatestDataInLocationTreeLocationsLocationIdAllVarNameLatestGet(
        varName,
        locationId,
        project,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the data for a given var_name in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read All Latest Data In Tree
   * @param {VarName} varName
   * @param {Array<ProjectData>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readAllLatestDataInTreeSensorsAllVarNameLatestGet(
    varName: VarName,
    project?: Array<ProjectData>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readAllLatestDataInTreeSensorsAllVarNameLatestGet(varName, project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the sensors in the entire sub-tree of the given location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read All Sensors In Location Tree
   * @param {string | null} locationId
   * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(
    locationId: string | null,
    project?: Array<ProjectSensors>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readAllSensorsInLocationTreeLocationsLocationIdAllSensorsGet(locationId, project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the sensors in the entire sub-tree of the root location (WARNING: big queries i.e. too many sensors will be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read All Sensors In Tree
   * @param {Array<ProjectSensors>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readAllSensorsInTreeSensorsAllGet(
    project?: Array<ProjectSensors>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readAllSensorsInTreeSensorsAllGet(project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read the locations, sensors and latest sensor data values of the direct children of the root location  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read Base Direct Children
   * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readBaseDirectChildrenLocationsGet(
    project?: Array<ProjectDirectChildren>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readBaseDirectChildrenLocationsGet(project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read the proprties of the user root location.
   * @summary Read Base Location Properties
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readBaseLocationPropertiesLocationsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .readBaseLocationPropertiesLocationsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Test API access
   * @summary Test API response (Hello World)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readHelloWorldGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .readHelloWorldGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the latest proprties and data for a given sensor.  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read Latest Sensor Information
   * @param {string} sensorId
   * @param {Array<ProjectSensorLatest>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readLatestSensorInformationSensorsSensorIdGet(
    sensorId: string,
    project?: Array<ProjectSensorLatest>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readLatestSensorInformationSensorsSensorIdGet(sensorId, project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the daily metric data for sensors that are direct children of a given location for given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. many sensors, long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
   * @summary Read Location Direct Child Daily Data Range
   * @param {VarName} varName
   * @param {string | null} locationId
   * @param {string} dStart
   * @param {string} dEnd
   * @param {Set<number>} [selectedHours]
   * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
    varName: VarName,
    locationId: string | null,
    dStart: string,
    dEnd: string,
    selectedHours?: Set<number>,
    project?: Array<DailyMetricProjectTypes>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readLocationDirectChildDailyDataRangeLocationsLocationIdVarNameDailyGet(
        varName,
        locationId,
        dStart,
        dEnd,
        selectedHours,
        project,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read the locations, sensors and latest data values for those that are direct children of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read Location Direct Children
   * @param {string | null} locationId
   * @param {Array<ProjectDirectChildren>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readLocationDirectChildrenLocationsLocationIdGet(
    locationId: string | null,
    project?: Array<ProjectDirectChildren>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readLocationDirectChildrenLocationsLocationIdGet(locationId, project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read the proprties of the given location.
   * @summary Read Location Properties
   * @param {string | null} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readLocationPropertiesLocationsLocationIdGet(
    locationId: string | null,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readLocationPropertiesLocationsLocationIdGet(locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read a summary report of data from all sensors under given location tree for given date range (YYYY-MM-DD), typically for monthly reporting.  (WARNING: big queries i.e. many sensors, long time range may take some time)
   * @summary Read Location Sensor Report
   * @param {string | null} locationId
   * @param {string} dStart
   * @param {string} dEnd
   * @param {Set<number>} [selectedHours]
   * @param {boolean} [includeWeekends]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readLocationSensorReportLocationsLocationIdReportGet(
    locationId: string | null,
    dStart: string,
    dEnd: string,
    selectedHours?: Set<number>,
    includeWeekends?: boolean,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readLocationSensorReportLocationsLocationIdReportGet(
        locationId,
        dStart,
        dEnd,
        selectedHours,
        includeWeekends,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the locations in the entire sub-tree of the given location_id  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read Location Tree
   * @param {string | null} locationId
   * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readLocationTreeLocationsLocationIdAllGet(
    locationId: string | null,
    project?: Array<ProjectLocations>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readLocationTreeLocationsLocationIdAllGet(locationId, project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the locations in the entire sub-tree of the root location (WARNING: big queries i.e. too many locations may be truncated)  \'project\' allows specifying particular properties attributes to return (see example response) , giving no \'project\' items or \'project=all\' will return all available attributes.
   * @summary Read Root Location Tree
   * @param {Array<ProjectLocations>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readRootLocationTreeLocationsAllGet(
    project?: Array<ProjectLocations>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readRootLocationTreeLocationsAllGet(project, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read all the daily metric data for a given sensor for a given date range (YYYY-MM-DD) and varName.  (WARNING: big queries i.e. long time range may take some time)  Using \'project=day\' (rather than \'all\'/\'hours\') will avoid returning hours data if not required.
   * @summary Read Sensor Daily Data Range
   * @param {VarName} varName
   * @param {string} sensorId
   * @param {string} dStart
   * @param {string} dEnd
   * @param {Set<number>} [selectedHours]
   * @param {Array<DailyMetricProjectTypes>} [project] Data fields to query and include in response
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
    varName: VarName,
    sensorId: string,
    dStart: string,
    dEnd: string,
    selectedHours?: Set<number>,
    project?: Array<DailyMetricProjectTypes>,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readSensorDailyDataRangeSensorsSensorIdVarNameDailyGet(
        varName,
        sensorId,
        dStart,
        dEnd,
        selectedHours,
        project,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Read historic data for a given sensor_id and given data var_name between the specified tstart and tend times, given in seconds since Epoch (Default is the last XX hours)  Example var_names are \'clientsWiFi\' or \'temperatureC\' a good way to find what data is available is to use /sensors/{sensor_id}
   * @summary Read Sensor Historic Data
   * @param {VarName} varName
   * @param {string} sensorId
   * @param {number | null} [tstart]
   * @param {number | null} [tend]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(
    varName: VarName,
    sensorId: string,
    tstart?: number | null,
    tend?: number | null,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .readSensorHistoricDataSensorsSensorIdVarNameHistoryGet(
        varName,
        sensorId,
        tstart,
        tend,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return access details of current user
   * @summary Read Users Me
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readUsersMeUsersMeGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .readUsersMeUsersMeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Disable BACnet/IP for a sensor/gateway. Note: Can only disable gateway from the gateway sensor. Note: After disabling gateway it may take a few minutes for the sensor to become available again.
   * @summary Remove Bacnet Config
   * @param {string} sensorId
   * @param {boolean} areYouSure
   * @param {boolean | null} [removeGateway]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public removeBacnetConfigSensorsSensorIdBacnetDelete(
    sensorId: string,
    areYouSure: boolean,
    removeGateway?: boolean | null,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .removeBacnetConfigSensorsSensorIdBacnetDelete(sensorId, areYouSure, removeGateway, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove a given user from access to the location. (User will still be able to log in for a period of time but have no access to data, unless \'force_delete\' is specified).
   * @summary Remove User Access
   * @param {string} userId
   * @param {string | null} locationId
   * @param {boolean} [forceDelete]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public removeUserAccessUsersLocationIdUserIdRemoveDelete(
    userId: string,
    locationId: string | null,
    forceDelete?: boolean,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .removeUserAccessUsersLocationIdUserIdRemoveDelete(userId, locationId, forceDelete, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Set the stored floorplan of the location, floorplan must be GeoJSON
   * @summary Set Location Floorplan
   * @param {string | null} locationId
   * @param {GeoJSON} geoJSON
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public setLocationFloorplanLocationsLocationIdFloorplanPost(
    locationId: string | null,
    geoJSON: GeoJSON,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .setLocationFloorplanLocationsLocationIdFloorplanPost(locationId, geoJSON, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of the visible subsensors and APs for a given gateway sensor. Only for WiFi/gateway sensors.
   * @summary Update Gateway Config
   * @param {string} sensorId
   * @param {GatewayConfig} gatewayConfig
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateGatewayConfigSensorsSensorIdConfigPut(
    sensorId: string,
    gatewayConfig: GatewayConfig,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .updateGatewayConfigSensorsSensorIdConfigPut(sensorId, gatewayConfig, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the proprties of the given location. (moving location i.e. changing the location attribute to move the tree to a new location, is a work in progress)
   * @summary Update Location Properties
   * @param {string | null} locationId
   * @param {PropLocationItemIn} propLocationItemIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateLocationPropertiesLocationsLocationIdPut(
    locationId: string | null,
    propLocationItemIn: PropLocationItemIn,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .updateLocationPropertiesLocationsLocationIdPut(locationId, propLocationItemIn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update BACnet/IP settings for a sensor/gateway. Note: Can only update gateway settings using the gateway sensor. Note: Updating building/vlan network settings may take a few minutes to apply.
   * @summary Update Sensor Bacnet Config
   * @param {string} sensorId
   * @param {BacnetSettingsUpdate} bacnetSettingsUpdate
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateSensorBacnetConfigSensorsSensorIdBacnetPut(
    sensorId: string,
    bacnetSettingsUpdate: BacnetSettingsUpdate,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .updateSensorBacnetConfigSensorsSensorIdBacnetPut(sensorId, bacnetSettingsUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the properties of the given sensor
   * @summary Update Sensor Properties
   * @param {string} sensorId
   * @param {PropSensorItemIn} propSensorItemIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateSensorPropertiesSensorsSensorIdPut(
    sensorId: string,
    propSensorItemIn: PropSensorItemIn,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .updateSensorPropertiesSensorsSensorIdPut(sensorId, propSensorItemIn, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the sensor shadow config. Only for WiFi/gateway sensors.
   * @summary Update Sensor Shadow Config If Exists
   * @param {string} sensorId
   * @param {ShadowState} shadowState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut(
    sensorId: string,
    shadowState: ShadowState,
    options?: RawAxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .updateSensorShadowConfigIfExistsSensorsSensorIdShadowPut(sensorId, shadowState, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
