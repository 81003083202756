import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Plot from './PlotlyCustom';
import { getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import { VarName } from '../../utils/varNames';
import { BandValues } from '../SensorArrayWidgets/base';
import { themeProps } from '../../styles/theme';
import { getDataValueString } from '../HelperComponents/DataValueString';
import { setActiveMarker, setHighlightedItem, setSelectedBand } from '../../state/actions';
import { getActiveMarker, getLocSensorStatusData, getThemeMode } from '../../state/selectors';
import { ThemeMode } from '../../state/types';

interface DoughnutPlotProps {
  varName: VarName;
  locMarkerId?: string; // to generate small sized summary ring plot for child locations when called from map
}

export function DoughnutPlot({ varName, locMarkerId }: DoughnutPlotProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeMarker = useSelector(getActiveMarker);
  const themeMode = useSelector(getThemeMode);
  const locSensorStatusData = useSelector(getLocSensorStatusData);

  const onlineData = useMemo(() => {
    const locVarData = locSensorStatusData.onlineData.get(varName) ?? [];
    let data = locVarData;
    if (locMarkerId)
      data = locVarData.filter((item) => locMarkerId && item.location?.startsWith(locMarkerId));
    return data;
  }, [locMarkerId, locSensorStatusData.onlineData, varName]);

  const offlineData = useMemo(() => {
    const locVarData = locSensorStatusData.offlineData.get(varName) ?? [];
    let data = locVarData;
    if (locMarkerId)
      data = locVarData.filter((item) => locMarkerId && item.location?.startsWith(locMarkerId));
    return data;
  }, [locMarkerId, locSensorStatusData.offlineData, varName]);

  let lineColour = theme.palette.text.secondary;
  // HACK - Manually set the line colour to match the highlighted background
  if (varName === activeMarker) {
    if (themeMode === ThemeMode.dark) {
      lineColour = themeProps.colors.summaryLineDark;
    } else {
      lineColour = themeProps.colors.summaryLineLight;
    }
  }

  // calculate for centered ring plot annotation
  const avgValue =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    onlineData.reduce((acc, curr) => acc + curr?.value ?? undefined, 0) / onlineData.length;

  const avgValueBand = getDataBandParams(varName, avgValue);
  const varNameBand = varNameBandParams[varName] ?? [];

  const perBandSums = () => {
    const result = varNameBand?.map(({ color }) => ({
      total: 0,
      pct: 0,
      colour: color,
    })) as BandValues[];
    onlineData.forEach(({ value }) => {
      for (let i = 0; i < result.length; i++) {
        if ((value ?? Infinity) <= varNameBand[i].upperBound) {
          result[i].total += 1;
          break;
        }
      }
    });
    // add offline data length to the per band sum array
    result.push({ total: offlineData.length, pct: 0, colour: themeProps.colors.lightGrey });
    return result;
  };

  // Varname band that includes offline band
  const combinedBand = useMemo(() => {
    const bands = [...(varNameBandParams[varName] ?? [])];
    if (bands && varName !== VarName.OnlineStatus) {
      bands.push({
        upperBound: NaN,
        color: themeProps.colors.lightGrey,
        text: 'Offline',
        label: 'Offline',
      });
    }
    return bands;
  }, [varName]);

  const plotData = [
    {
      values: perBandSums().flatMap((item) => item.total),
      labels: combinedBand.flatMap((item) => item.label),
      domain: { column: 0 },
      hole: 0.75,
      type: 'pie',
      marker: {
        colors: perBandSums().flatMap((item) => item.colour),
        line: {
          color: lineColour,
          width: 2,
        },
      },
      textinfo: 'none',
      direction: 'clockwise',
      sort: false,
    },
  ] as Plotly.Data[];

  const plotLayout = {
    title: {
      x: 0.5,
      y: 0.5,
    },
    font: { family: themeProps.fontFamily.body },
    annotations: [
      {
        font: {
          size: locMarkerId ? 14 : 20,
          color: avgValueBand?.color ?? theme.palette.text.primary,
        },
        showarrow: false,
        // for motion event value needs to be checked from motionthreshold
        text: getDataValueString(avgValue, varName, undefined, undefined, !locMarkerId),
        x: 0.5,
        y: 0.55,
      },
      {
        font: {
          size: 16,
          color: avgValueBand?.color ?? themeProps.colors.grey, // default color grey
        },
        showarrow: false,
        text: locMarkerId ? '' : avgValueBand?.label ?? '',
        x: 0.5,
        y: 0.4,
      },
    ],
    width: locMarkerId ? 60 : 200,
    height: locMarkerId ? 60 : 200,
    margin: {
      l: locMarkerId ? 0 : 10,
      r: locMarkerId ? 0 : 10,
      b: locMarkerId ? 0 : 10,
      t: locMarkerId ? 0 : 10,
    },
    showlegend: false,
    plot_bgcolor: `${theme.palette.primary.main}00`, // transparent
    paper_bgcolor: `${theme.palette.primary.main}00`,
  } as Plotly.Layout;

  const handleMouseEvent = (event: Plotly.PlotMouseEvent) => {
    if (!locMarkerId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const bandPos = event.points[0].pointNumbers[0]; // point number is missing in some case, for instance offline band in online status

      if (varName === VarName.OnlineStatus) {
        dispatch(setSelectedBand({ varName, band: varNameBand[bandPos] }));
      } else if (bandPos !== combinedBand.length - 1)
        dispatch(setSelectedBand({ varName, band: combinedBand[bandPos] }));
      else dispatch(setSelectedBand(null));
    }
  };

  const handleUnHover = () => {
    if (!locMarkerId) {
      dispatch(setSelectedBand(null));
      dispatch(setHighlightedItem({ id: '' }));
    }
  };

  const handleClick = (event: Plotly.PlotMouseEvent) => {
    handleMouseEvent(event);
    dispatch(setActiveMarker(varName));
    navigate('/activeSource');
  };

  return (
    <Plot
      data={plotData}
      layout={plotLayout}
      config={{ displayModeBar: false }}
      useResizeHandler
      onClick={handleClick}
      onHover={(e) => handleMouseEvent(e)}
      onUnhover={handleUnHover}
    />
  );
}
DoughnutPlot.defaultProps = {
  locMarkerId: undefined,
};
export default DoughnutPlot;
