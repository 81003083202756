import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import { handleLogout } from '../Shell/helpers';
import useGlobalStyles from '../styles/index';
import { VisibleSubSensor } from '../services/api';
import {
  GatewayVisibleSubsensors,
  filterNewSensorsInLocation,
  getAllGatewayVisibleSubsensors,
} from '../Widgets/SensorConfig/helpers';
import {
  getBleLocSwitchStatus,
  getBleSensors,
  getCurrentLocation,
  getSensorsById,
  getSensorsByLocId,
  getTempUser,
} from '../state/selectors';
import { setCurrentLocation, setTempUser } from '../state/actions';
import { StorageTypes, persistState } from '../utils/persistentState';
import Header from '../components/Header';
import NewSensorsList from '../Widgets/AppInstall/NewSensorsList';
import InstallContainer from '../Widgets/AppInstall/InstallContainer';
import LoaderWithBackdrop from '../Widgets/HelperComponents/LoaderWithBackdrop';

function AppInstallSensor(): JSX.Element {
  const dispatch = useDispatch();
  const { sensorId } = useParams() as { sensorId: string };
  const { user } = useAuthenticator((context) => [context.user]);
  const globalClasses = useGlobalStyles();
  const [selectedSensorId, setSelectedSensorId] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState<string>();

  const [newSensors, setNewSensors] = useState<VisibleSubSensor[]>();
  const [gatewayVisibleSubsensors, setGatewayVisibleSubsensors] =
    useState<GatewayVisibleSubsensors[]>();

  const currentLocation = useSelector(getCurrentLocation);
  const tempUser = useSelector(getTempUser);
  const bleSensors = useSelector(getBleSensors);
  const allSensorDetails = useSelector(getSensorsById);
  const allowAutoLocSwitch = useSelector(getBleLocSwitchStatus);
  const sensorsByLocId = useSelector(getSensorsByLocId);
  const sensorsById = useSelector(getSensorsById);

  useEffect(() => {
    if (sensorId) setSelectedSensorId(sensorId);
    else setSelectedSensorId('');
  }, [sensorId]);

  useEffect(() => {
    if (bleSensors && bleSensors.length > 0) {
      for (let i = 0; i < bleSensors.length; i++) {
        const sensorDetails = allSensorDetails.get(bleSensors[i].id);
        if (sensorDetails) {
          // change location
          const { location } = sensorDetails;
          if (location && location !== currentLocation && allowAutoLocSwitch) {
            dispatch(setCurrentLocation(location));
            persistState(location, StorageTypes.CurrentLocation);
          }
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSensorDetails, allowAutoLocSwitch]);

  useEffect(() => {
    if (!user) {
      // to avoid location detail from tempUser where location comes as #
      dispatch(setTempUser(null));
      handleLogout(tempUser, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getInitialData = useCallback(() => {
    setLoading(true);
    setAlertMsg('');

    getAllGatewayVisibleSubsensors(currentLocation, sensorsById, sensorsByLocId)
      .then((visibleSubsensors) => {
        setGatewayVisibleSubsensors(visibleSubsensors);
        filterNewSensorsInLocation(visibleSubsensors, sensorsByLocId.get(currentLocation) ?? [])
          .then((allSensors) => {
            setLoading(false);
            if (allSensors) {
              setNewSensors(allSensors);
            }
          })
          .catch(() => setAlertMsg('Error filtering new sensors in location'));
      })
      .catch(() => {
        setGatewayVisibleSubsensors(undefined);
        setNewSensors(undefined);
        setLoading(false);
      });
  }, [currentLocation, sensorsById, sensorsByLocId]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const handleRefresh = () => {
    setGatewayVisibleSubsensors(undefined);
    setNewSensors(undefined);
    getInitialData();
  };

  return (
    <div className={globalClasses.bodyContent}>
      {/* Header component is hidden during install process to avoid location change */}
      {!selectedSensorId && <Header />}
      {loading && <LoaderWithBackdrop />}
      {user && !tempUser && (
        <Box sx={{ marginTop: '1rem' }}>
          {alertMsg && <Alert severity="error">{alertMsg} </Alert>}
          {selectedSensorId ? (
            <InstallContainer
              selectedSensorId={selectedSensorId}
              handleRefresh={handleRefresh}
              gatewayVisibleSubsensors={gatewayVisibleSubsensors}
            />
          ) : (
            <NewSensorsList
              loading={loading}
              newSensors={newSensors}
              handleRefresh={handleRefresh}
              setSelectedSensorId={setSelectedSensorId}
            />
          )}
        </Box>
      )}
    </div>
  );
}

export default AppInstallSensor;
