import {
  LocationTreeItems,
  Position,
  PropLocationItem,
  SensorLatest,
  UserPublic,
} from '../services/api';
import { VarName } from '../utils/varNames';
import {
  LocationFloorplanPayload,
  ActiveItem,
  SortType,
  SearchPayload,
  HoursSelection,
  TempUser,
  NearBySensor,
  ThemeMode,
  GhostParams,
  SelectedBand,
} from './types';

export enum ActionType {
  SET_TEMP_USER = 'SET_TEMP_USER',
  SET_USER_DETAILS = 'SET_USER_DETAILS',
  SET_USER_POSITION = 'SET_USER_POSITION',
  SET_LOCATION_DATA = 'SET_LOCATION_DATA',
  UPDATE_LOCATION_DATA = 'UPDATE_LOCATION_DATA',
  REMOVE_LOCATION_DATA = 'REMOVE_LOCATION_DATA',
  SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION',
  SET_CURRENT_LOCATION_CHILDREN = 'SET_CURRENT_LOCATION_CHILDREN',
  SET_SENSOR_NAMES = 'SET_SENSOR_NAMES',
  SET_SENSORS_BY_ID = 'SET_SENSORS_BY_ID',
  REMOVE_SENSOR_DATA = 'REMOVE_SENSOR_DATA',
  SET_SENSOR_VARS = 'SET_SENSOR_VARS',
  SET_REFRESH_TIMESTAMP = 'SET_REFRESH_TIMESTAMP',
  SET_SELECTED_START_DATE = 'SET_SELECTED_START_DATE',
  SET_SELECTED_END_DATE = 'SET_SELECTED_END_DATE',
  SET_SELECTED_SENSORS = 'SET_SELECTED_SENSORS',
  SET_ALL_LATEST_VARNAME_VALUES = 'SET_ALL_LATEST_VARNAME_VALUES',
  GO_FETCH_SENSOR_DETAILS = 'GO_FETCH_SENSOR_DETAILS',
  GO_FETCH_ALL_LATEST_VARNAME = 'GO_FETCH_ALL_LATEST_VARNAME',
  GO_FETCH_LOCATION_FLOORPLAN = 'GO_FETCH_LOCATION_FLOORPLAN',
  GO_FETCH_REFRESH = 'GO_FETCH_REFRESH',
  SET_LOCATION_FLOORPLAN = 'SET_LOCATION_FLOORPLAN',
  SET_MAP_SIZE = 'SET_MAP_SIZE',
  SET_HIGHLIGHTED_ITEM = 'SET_HIGHLIGHTED_ITEM',
  SET_ACTIVE_MARKER = 'SET_ACTIVE_MARKER',
  SET_CLICKED_ITEM = 'SET_CLICKED_ITEM',
  TOGGLE_DASHBOARD_PANEL = 'TOGGLE_DASHBOARD_PANEL',
  SET_DASHBOARD_PANEL = 'SET_DASHBOARD_PANEL',
  TOGGLE_ACTIVE_PLOT_VAR = 'TOGGLE_ACTIVE_PLOT_VAR',
  SHOW_HELP = 'SHOW_HELP',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_SORT_BY = 'SET_SORT_BY',
  SET_SELECTED_HOURS = 'SET_SELECTED_HOURS',
  SET_MOTION_THRESHOLD = 'SET_MOTION_THRESHOLD',
  TOGGLE_SHOW_FLOORPLAN_LABELS = 'TOGGLE_SHOW_FLOORPLAN_LABELS',
  TOGGLE_SHOW_SENSOR_LABELS = 'TOGGLE_SHOW_SENSOR_LABELS',
  ALLOW_BLE_LOC_SWITCH = 'ALLOW_BLE_LOC_SWITCH',
  SET_BLE_SENSORS = 'SET_BLE_SENSORS',
  SET_BLE_WEBVIEW_URL = 'SET_BLE_WEBVIEW_URL',
  SET_SHOW_NAV_DRAWER = 'SET_SHOW_NAV_DRAWER',
  SET_THEME_MODE = 'SET_THEME_MODE',
  SET_GHOST_PARAMS = 'SET_GHOST_PARAMS',
  SET_STACKED_TRACES = 'SET_STACKED_TRACES',
  SET_SELECTED_BAND = 'SET_SELECTED_BAND',
  TOGGLE_SHOW_ADVANCED_ANALYSIS = 'TOGGLE_SHOW_ADVANCED_ANALYSIS',
  CLEAR_REDUX_STORE = 'CLEAR_REDUX_STORE',
  SET_AVAILABLE_VARS = 'SET_AVAILABLE_VARS',
}

export interface SetMotionThreshold {
  type: ActionType.SET_MOTION_THRESHOLD;
  payload: [number, number];
}

export interface SetSelectedHours {
  type: ActionType.SET_SELECTED_HOURS;
  payload: HoursSelection;
}

export interface SetSortByAction {
  type: ActionType.SET_SORT_BY;
  payload: SortType;
}

export interface SetSearchTermAction {
  type: ActionType.SET_SEARCH_TERM;
  payload: SearchPayload;
}
export interface ToggleActivePlotVarAction {
  type: ActionType.TOGGLE_ACTIVE_PLOT_VAR;
  payload: VarName;
}

export interface ToggleDashboardPanelAction {
  type: ActionType.TOGGLE_DASHBOARD_PANEL;
  payload: VarName;
}

export interface SetDashboardPanelAction {
  type: ActionType.SET_DASHBOARD_PANEL;
  payload: VarName;
}
export interface SetClickedItemAction {
  type: ActionType.SET_CLICKED_ITEM;
  payload: ActiveItem;
}

export interface SetActiveMarkerAction {
  type: ActionType.SET_ACTIVE_MARKER;
  payload: VarName;
}

export interface SetHighlightedItemAction {
  type: ActionType.SET_HIGHLIGHTED_ITEM;
  payload: ActiveItem;
}

export interface SetSensorVarsAction {
  type: ActionType.SET_SENSOR_VARS;
  payload: Array<VarName>;
}

export interface SetRefreshTimeStamp {
  type: ActionType.SET_REFRESH_TIMESTAMP;
  payload: { varName: VarName; time: number };
}

export interface SetLocationDataAction {
  type: ActionType.SET_LOCATION_DATA;
  payload: LocationTreeItems | null;
}

export interface UpdateLocationDataAction {
  type: ActionType.UPDATE_LOCATION_DATA;
  payload: PropLocationItem;
}

export interface RemoveLocationDataAction {
  type: ActionType.REMOVE_LOCATION_DATA;
  payload: string;
}

export interface SetCurrentLocationAction {
  type: ActionType.SET_CURRENT_LOCATION;
  payload: string;
}

export interface SetCurrentLocationDirectChildrenAction {
  type: ActionType.SET_CURRENT_LOCATION_CHILDREN;
  payload: PropLocationItem[];
}

export interface SetLocationFloorplanAction {
  type: ActionType.SET_LOCATION_FLOORPLAN;
  payload: LocationFloorplanPayload;
}

export interface SetTempUserAction {
  type: ActionType.SET_TEMP_USER;
  payload: TempUser | null;
}

export interface SetUserPublicAction {
  type: ActionType.SET_USER_DETAILS;
  payload: UserPublic | null;
}

export interface SetUserPosition {
  type: ActionType.SET_USER_POSITION;
  payload: Position | null;
}

export interface ToggleShowFloorplanLabelsAction {
  type: ActionType.TOGGLE_SHOW_FLOORPLAN_LABELS;
}

export interface ToggleShowSensorLabelsAction {
  type: ActionType.TOGGLE_SHOW_SENSOR_LABELS;
}

export interface SetBleLocSwitchStatus {
  type: ActionType.ALLOW_BLE_LOC_SWITCH;
  payload: boolean;
}

export interface SetBleSensors {
  type: ActionType.SET_BLE_SENSORS;
  payload: NearBySensor[] | undefined;
}

export interface SetBleWebViewUrl {
  type: ActionType.SET_BLE_WEBVIEW_URL;
  payload: string;
}

export interface SetSensorsByIdAction {
  type: ActionType.SET_SENSORS_BY_ID;
  payload: SensorLatest[];
}

export interface RemoveSensorDataAction {
  type: ActionType.REMOVE_SENSOR_DATA;
  payload: string;
}

export interface GoFetchSensorDetailsAction {
  type: ActionType.GO_FETCH_SENSOR_DETAILS;
  payload: string;
}

export interface SetSelectedSensorsAction {
  type: ActionType.SET_SELECTED_SENSORS;
  payload: string[];
}

export interface SetSelectedStartDateAction {
  type: ActionType.SET_SELECTED_START_DATE;
  payload: Date;
}

export interface SetSelectedEndDateAction {
  type: ActionType.SET_SELECTED_END_DATE;
  payload: Date;
}

export interface GoFetchAllLatestVarnameAction {
  type: ActionType.GO_FETCH_ALL_LATEST_VARNAME;
  payload: VarName;
}

export interface GoFetchLocationFloorplanAction {
  type: ActionType.GO_FETCH_LOCATION_FLOORPLAN;
  payload: string;
}

export interface GoFetchRefresh {
  type: ActionType.GO_FETCH_REFRESH;
}

export interface SetMapSizeAction {
  type: ActionType.SET_MAP_SIZE;
  payload: 'collapsed' | 'normal' | 'full';
}

export interface ShowHelpAction {
  type: ActionType.SHOW_HELP;
  payload: boolean;
}

export interface SetShowNavDrawer {
  type: ActionType.SET_SHOW_NAV_DRAWER;
  payload: boolean;
}

export interface SetThemeMode {
  type: ActionType.SET_THEME_MODE;
  payload: ThemeMode;
}

export interface SetGhostParams {
  type: ActionType.SET_GHOST_PARAMS;
  payload: GhostParams;
}

export interface SetStackedTraces {
  type: ActionType.SET_STACKED_TRACES;
  payload: boolean;
}

export interface SetSelectedBand {
  type: ActionType.SET_SELECTED_BAND;
  payload: SelectedBand | null;
}

export interface ToggleShowAdvancedAnalysis {
  type: ActionType.TOGGLE_SHOW_ADVANCED_ANALYSIS;
  payload: boolean;
}

export interface SetAvailableVars {
  type: ActionType.SET_AVAILABLE_VARS;
  payload: VarName[];
}

export interface ClearReduxStore {
  type: ActionType.CLEAR_REDUX_STORE;
}

export type ActionTypes =
  | SetTempUserAction
  | SetUserPublicAction
  | SetUserPosition
  | SetLocationDataAction
  | UpdateLocationDataAction
  | RemoveLocationDataAction
  | SetCurrentLocationAction
  | SetCurrentLocationDirectChildrenAction
  | SetLocationFloorplanAction
  | SetSensorsByIdAction
  | RemoveSensorDataAction
  | SetSelectedSensorsAction
  | SetSelectedStartDateAction
  | SetSelectedEndDateAction
  | SetSensorVarsAction
  | GoFetchSensorDetailsAction
  | GoFetchAllLatestVarnameAction
  | GoFetchLocationFloorplanAction
  | GoFetchRefresh
  | SetMapSizeAction
  | SetHighlightedItemAction
  | SetClickedItemAction
  | ToggleDashboardPanelAction
  | SetDashboardPanelAction
  | ToggleActivePlotVarAction
  | SetActiveMarkerAction
  | ShowHelpAction
  | SetSearchTermAction
  | SetSortByAction
  | SetSelectedHours
  | SetMotionThreshold
  | ToggleShowFloorplanLabelsAction
  | ToggleShowSensorLabelsAction
  | SetBleLocSwitchStatus
  | SetBleSensors
  | SetBleWebViewUrl
  | SetShowNavDrawer
  | SetThemeMode
  | SetGhostParams
  | SetStackedTraces
  | SetSelectedBand
  | SetRefreshTimeStamp
  | ToggleShowAdvancedAnalysis
  | SetAvailableVars
  | ClearReduxStore;
