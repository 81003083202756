import { useDispatch, useSelector } from 'react-redux';
import { Data, Layout } from 'plotly.js';
import React from 'react';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';

import Plot from './PlotlyCustom';
import { stackIcon } from './plotCommon';
import {
  getShowStackedTraces,
  getSelectedEndDate,
  getSelectedStartDate,
} from '../../state/selectors';
import { setSelectedEndDate, setSelectedStartDate, setStackedTraces } from '../../state/actions';
import { VarName } from '../../utils/varNames';
import { getEndOfDay, getStartOfDay } from '../../utils/functions';
import useStyles from '../../styles';

interface StackedPlotProps {
  plotData: Data[];
  layout: Partial<Layout>;
  varNames: VarName[];
}

export function StackedPlot({ plotData, layout, varNames }: StackedPlotProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showStackedTraces = useSelector(getShowStackedTraces);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  const layoutRanged = {
    ...layout,
    xaxis: {
      ...layout.xaxis,
      range: [startDate, endDate],
    },
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasNoPlotData = plotData.filter((data: PlotData) => data.x.length > 0).length === 0;

  function handleRelayout(event: Plotly.PlotRelayoutEvent) {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      let newStart = new Date(event['xaxis.range[0]']);
      let newEnd = new Date(event['xaxis.range[1]']);
      // Don't dispatch if it is a zoom into current data, only if the new range is larger (pan)
      if (dayjs(newStart).isBefore(dayjs(startDate)) || dayjs(newEnd).isAfter(dayjs(endDate))) {
        // If dragged to the future limit to now and keep range from drag
        const now = new Date();
        if (newEnd > now) {
          const diff = Math.max(newEnd.getTime() - now.getTime(), 24 * 3600 * 1000);
          newEnd = getEndOfDay(now);
          newStart = getStartOfDay(new Date(newStart.getTime() - diff));
        }
        dispatch(setSelectedStartDate(newStart));
        dispatch(setSelectedEndDate(newEnd));
      }
    }
  }

  return (
    <div style={{ borderRadius: '8px' }}>
      <Plot
        data={
          hasNoPlotData
            ? [{ x: [startDate, endDate], y: [0, 0], marker: { opacity: 0 }, mode: 'markers' }] // Fake data to keep xaxis range
            : plotData
        }
        layout={layoutRanged}
        useResizeHandler
        onRelayout={(e) => handleRelayout(e)}
        style={{
          width: '100%',
          height: varNames.length > 1 ? `${200 * varNames.length}px` : '400px',
        }}
        config={{
          modeBarButtonsToAdd: [
            {
              name: `stackTraces-${showStackedTraces}`,
              title: 'Stacked/Alternate Traces',
              icon: stackIcon,
              click: () => dispatch(setStackedTraces(!showStackedTraces)),
            },
          ],
          modeBarButtonsToRemove: [
            'lasso2d',
            'select2d',
            'zoomOut2d',
            // 'zoom2d',
            // 'pan2d',
            'zoomIn2d',
            'autoScale2d',
          ],
          displaylogo: false,
        }}
      />
      {hasNoPlotData && (
        <Typography variant="body1" className={classes.plotAlertText}>
          No data (Select different sensors, data type or time period)
        </Typography>
      )}
    </div>
  );
}
export default StackedPlot;
