import React from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material';
import { VarName, varNameDetails } from '../../utils/varNames';
import { dataColours, varNameBandParams } from '../../utils/dataBandParams';
import { getPlotCommonLayout, SensorMetricPlotItem, TimeRange } from './plotCommon';
import { hexToRgb } from '../Map/mapHelpers';
import { getShowStackedTraces } from '../../state/selectors';
import StackedPlot from './StackedPlot';
import { MetricOptions } from '../../components/DateRangePicker';

/*
 * Helper functions
 */

// Shapes the data for Plotly
const formatPlotData = (
  sensorMetricItem: SensorMetricPlotItem,
  yAxisIndex: number,
  showStackedTraces: boolean
): Plotly.Data => {
  const { sensorName, varName, color, ghost, metrics } = { ...sensorMetricItem };
  const { label, metric } = { ...varNameDetails[varName] };
  const hasTotUtl =
    metrics.find((item) => item.tot !== undefined || item.utl !== undefined) !== undefined;
  const isBarData = hasTotUtl && (!showStackedTraces || varNameDetails[varName].stackable);
  const plotData = {
    x: metrics.map((item) => item.date) as string[],
    y: metrics.map(
      (item) => item.tot ?? (item.utl !== undefined ? item.utl * 100 : undefined) ?? item.avg
    ) as number[],
    name: `${sensorName} - ${label} ${metric || ''}`,
    hovertemplate: `%{y} ${metric || ''} - %{x} <br> ${sensorName} <extra></extra>`,
    yaxis: `y${yAxisIndex > 0 ? yAxisIndex + 1 : ''}`,
    type: isBarData ? 'bar' : 'scatter',
    marker: color ? { color } : undefined,
  } as Plotly.Data;

  if (ghost) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plotData.line = { dash: 'dash' };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plotData.fillpattern = { shape: '/' };
    if (isBarData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      plotData.marker = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...plotData.marker,
        pattern: {
          shape: '/',
          opacity: 0.6,
          size: 10,
          solidity: 0.2,
        },
        line: {
          color,
          width: 1.5,
        },
      };
    }
  }
  // TODO Stacking works differently for bar charts, would need to create another overlaid y-axis
  // to stack data and ghosts separately in stacked bar plot
  return plotData;
};

const formatErrorBarPlot = (
  sensorMetricItem: SensorMetricPlotItem,
  yAxisIndex: number
): Plotly.Data => {
  const { sensorName, varName, color, metrics } = { ...sensorMetricItem };
  const { label, metric } = { ...varNameDetails[varName] };
  const dates = metrics.map((item) => item.date) as string[];
  const plotData = {
    x: [...dates, ...dates.reverse()],
    y: [
      ...(metrics.map((item) => item.max) as number[]),
      ...(metrics.map((item) => item.min) as number[]).reverse(),
    ],
    name: `${sensorName} - ${label} ${metric || ''}`,
    hovertemplate: `%{y} ${metric || ''} - %{x} <br> ${sensorName} (Max/Min) <extra></extra>`,
    yaxis: `y${yAxisIndex > 0 ? yAxisIndex + 1 : ''}`,
    type: 'scatter',
    fill: 'tozerox',
    line: { color: 'transparent' },
  } as Plotly.Data;
  if (color) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    plotData.fillcolor = `rgba(${hexToRgb(color, 0.2).join(', ')})`;
  }
  return plotData;
};

// Function to create the desired Map
function createMaxMinMap(
  dataItems: SensorMetricPlotItem[]
): Map<VarName, Map<string, { min: number; max: number }>> {
  const resultMap = new Map<VarName, Map<string, { min: number; max: number }>>();
  dataItems.forEach((sensorItem) => {
    if (!sensorItem.metrics || sensorItem.ghost) return; // Skip these
    sensorItem.metrics.forEach((metric) => {
      let varMap: Map<string, { min: number; max: number }>;
      const existingVarMap = resultMap.get(sensorItem.varName);
      if (existingVarMap !== undefined) {
        varMap = existingVarMap;
      } else {
        varMap = new Map<string, { min: number; max: number }>();
        resultMap.set(sensorItem.varName, varMap);
      }
      if (metric.date && metric.min !== undefined && metric.max !== undefined) {
        const dateEntry = varMap.get(metric.date);
        if (dateEntry) {
          // Update min and max if necessary
          dateEntry.min = Math.min(dateEntry.min, metric.min);
          dateEntry.max = Math.max(dateEntry.max, metric.max);
        } else {
          // Create new date entry
          varMap.set(metric.date, { min: metric.min, max: metric.max });
        }
      }
    });
  });
  return resultMap;
}

function createWaterFallTraces(
  varName: VarName,
  yAxisIndex: number,
  maxMinMap: Map<string, { min: number; max: number }>
) {
  const tracedata = [];
  const dates = Array.from(maxMinMap.keys()).sort();
  // TODO fix undefined
  const minValues = dates.map((key) => maxMinMap.get(key)?.min ?? 0);
  const maxValues = dates.map((key) => maxMinMap.get(key)?.max ?? 0);
  let dataBands = varNameBandParams[varName];
  if (!dataBands)
    dataBands = [
      {
        upperBound: Infinity,
        color: dataColours.blue,
        text: varNameDetails[varName].label,
        label: varNameDetails[varName].label,
      },
    ];

  for (let i = 0; i < dataBands.length; i++) {
    const value = [];
    const baseValue = [];
    const lowestValue = Math.min(...minValues);

    for (let j = 0; j < dates.length; j++) {
      if (i !== dataBands.length) {
        if (i === 0) {
          // adding base value
          let base;
          if (minValues[j] < dataBands[i].upperBound) base = minValues[j];
          // only add upperbound as base if lowest value exists in the band to avoid large maring/gaps in the graph
          else if (lowestValue <= dataBands[i].upperBound) base = dataBands[i].upperBound;
          // add value
          if (maxValues[j] > dataBands[i].upperBound && base !== undefined)
            value.push(dataBands[i].upperBound - base);
          else if (maxValues[j] < dataBands[i].upperBound && maxValues[j] > minValues[j])
            value.push(maxValues[j] - minValues[j]);
          else value.push(null);
          baseValue.push(base);
        } else {
          let base;
          if (maxValues[j] < dataBands[i - 1].upperBound) base = maxValues[j];
          else if (minValues[j] <= dataBands[i - 1].upperBound) base = dataBands[i - 1].upperBound;
          else if (minValues[j] <= dataBands[i].upperBound) base = minValues[j];
          // only add upperbound as base if lowest value exists in the band to avoid large maring/gaps in the graph
          else if (lowestValue <= dataBands[i].upperBound) base = dataBands[i].upperBound;

          // min value sometime has infinity in store
          if (base !== undefined && base !== -Infinity) {
            if (maxValues[j] > dataBands[i].upperBound) value.push(dataBands[i].upperBound - base);
            else if (
              maxValues[j] <= dataBands[i].upperBound &&
              maxValues[j] > dataBands[i - 1].upperBound
            ) {
              value.push(maxValues[j] - base);
            } else value.push(null);

            baseValue.push(base);
          }
        }
      } else {
        let base = null;
        if (minValues[j] < dataBands[i - 1].upperBound) base = dataBands[i - 1].upperBound;
        else base = minValues[j];

        if (maxValues[j] > dataBands[i - 1].upperBound && base !== undefined)
          value.push(maxValues[j]);
        else value.push(null);
        baseValue.push(base);
      }
    }

    if (value.some((x) => x !== null) && baseValue.length !== 0) {
      tracedata.push({
        x: dates,
        y: value,
        name: dataBands[i].label,
        base: baseValue,
        type: 'bar',
        yaxis: `y${yAxisIndex > 0 ? yAxisIndex + 1 : ''}`,
        marker: {
          color: `${dataBands[i].color}95`,
        },
        hovertemplate: ` %{x} <br> Max/Min <extra></extra>`,
      } as Plotly.Data);
    }
  }

  return tracedata;
}

/*
 * Plot Component
 */

export interface SensorMetricDataPlotProps {
  dataItems: SensorMetricPlotItem[];
  unselectedTimes?: TimeRange[];
  metricSelection: MetricOptions;
}

export function StackedMetricPlot({
  dataItems,
  unselectedTimes,
  metricSelection,
}: SensorMetricDataPlotProps): JSX.Element {
  const theme = useTheme();
  const showStackedTraces = useSelector(getShowStackedTraces);
  // Make a set of the unique varNames we have
  const varNames = dataItems.reduce((acc, dataItem) => {
    let out = acc;
    if (!acc.includes(dataItem.varName)) {
      out = [...acc, dataItem.varName];
    }
    return out;
  }, [] as VarName[]);

  const maxMinMap = createMaxMinMap(dataItems);
  const waterfalls = new Map<VarName, Plotly.Data[]>();

  // Format the data
  const plotData = dataItems.reduce((previous, dataItem) => {
    let next = previous;
    const varIdx = varNames.indexOf(dataItem.varName);
    // Only add if the varName is in the allowed list and has valid metrics
    if (varIdx > -1 && dataItem.metrics && dataItem.metrics[0].date) {
      const plotDataItem = formatPlotData(dataItem, varIdx, showStackedTraces);
      next = [...previous, plotDataItem];
      if (plotDataItem.type === 'scatter' && !dataItem.ghost) {
        if (!showStackedTraces) {
          const errorPlot = formatErrorBarPlot(dataItem, varIdx);
          next = [...next, errorPlot];
        } else if (!waterfalls.has(dataItem.varName)) {
          const varMinMax = maxMinMap.get(dataItem.varName);
          if (varMinMax) {
            const waterfall = createWaterFallTraces(dataItem.varName, varIdx, varMinMax);
            waterfalls.set(dataItem.varName, waterfall);
            next = [...next, ...waterfall];
          }
        }
      }
    }
    return next;
  }, [] as Plotly.Data[]);

  // Make the layout from the varNames and data ranges
  const layout = getPlotCommonLayout(
    varNames,
    theme,
    unselectedTimes,
    showStackedTraces,
    metricSelection
  );

  return <StackedPlot plotData={plotData} layout={layout} varNames={varNames} />;
}

StackedMetricPlot.defaultProps = {
  unselectedTimes: undefined,
};

export default StackedMetricPlot;
