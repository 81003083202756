import { GridSize } from '@mui/material/';
import SvgIcon from '@mui/material/SvgIcon';
import BoltIcon from '@mui/icons-material/Bolt';
import CloudIcon from '@mui/icons-material/Cloud';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RouterIcon from '@mui/icons-material/Router';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import sahara from '../styles/images/sensors/sahara.png';
import alpine from '../styles/images/sensors/alpine.png';
import x1 from '../styles/images/sensors/x1.png';
import base from '../styles/images/sensors/base.png';
import hoth from '../styles/images/sensors/hoth.png';
import hal from '../styles/images/sensors/hal.png';
import {
  BasicSensorType,
  GatewaySensorType,
  PropSensorItemCreate,
  SubSensorType,
} from '../services/api/api';
import { SensorPositionData } from '../Widgets/Map/types';

export interface SensorProperty {
  label: string;
  editable: boolean;
  autofilled: boolean;
}

export enum SensorCode {
  LF = 'LF',
  AMP = 'AMP',
  MS1 = 'MS1',
  MS4 = 'MS4',
  MSR = 'MSR',
  JTH = 'JTH',
  GAP = 'GAP',
  LF2 = 'LF2',
  LF3 = 'LF3',
  LF4 = 'LF4',
  LF5 = 'LF5',
  ME6 = 'ME6',
  SE9 = 'SE9',
  TSC = 'TSC',
  AQ2 = 'AQ2',
  EM1 = 'EM1',
}

export interface SensorTypeDetails {
  label: string;
  brandName: string;
  icon: typeof SvgIcon;
  image?: string;
  desc?: string;
}

export interface SensorItem {
  [key: string]: string | SensorPositionData | undefined;
}

export interface PropSensorItemCreateIndexed extends PropSensorItemCreate {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface APListHeader {
  id: number;
  label: string;
  gridSize: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  hideInSmallScreen?: boolean;
  xsGridSize?: GridSize;
}

export const sensorTypeDetails: Record<
  SubSensorType | GatewaySensorType | BasicSensorType,
  SensorTypeDetails
> = {
  'LF-WiFISensor': {
    label: 'Gateway',
    brandName: 'Base',
    icon: RouterIcon,
    image: base,
    desc: 'The “Base” is our long-range, broad level occupancy sensor, which determines the percentage occupancy levels by measuring the number active WiFi devices on a floor area c.3,000 – 5,000 sqft wide.<br /><br />It also acts as a gateway node for our other wireless IoT sensors, and forms the backbone of our system.<br /><br /> EXTRA: The Base integrates directly with your building infrastructure (e.g. your BMS), locally via industry standard protocols (BACnet/IP), to reduce energy consumption, lower carbon emissions and improve comfort & air quality.',
  },
  BeaconCO2: {
    label: 'Air Quality',
    brandName: 'Sensar',
    icon: CloudIcon,
    image: alpine,
    desc: 'The Sensar measures CO2 levels (via a dual-channel NDIR chip)<br /><br />It is powered via a USB C cable, and can be used with any 5V USB power supply, for example your phone, laptop or a power bank.',
  },
  BeaconEnergy: {
    label: 'Energy Meter',
    brandName: 'Pulse',
    icon: BoltIcon,
    image: hal,
    desc: 'The Pulse energy meter sensor is a non-invasive energy meter that can measure energy consumption via the load output pulses of a typical billing meter.',
  },
  BeaconOpenClose: {
    label: 'Open/Close',
    brandName: 'Hall',
    icon: MeetingRoomIcon,
    image: hal,
    desc: 'A magnetic contact sensor to detect Open/Close state of windows, doors etc.',
  },
  BeaconPMCO2: {
    label: 'Air Quality',
    brandName: 'Sahara',
    icon: CloudIcon,
    image: sahara,
    desc: 'The Sahara sensor is fully kitted out to measure: <br /><ul><li>Carbon Dioxide (CO2) levels with a dual-channel NDIR chip (which achieves the accuracy to WELL Standard)</li><li>Particulate Matter (PM2.5) With an additional option of measuring:</li></ul>',
  },
  BeaconTempHumCO2: {
    label: 'Air Quality (Battery)',
    brandName: 'Alpine',
    icon: CloudIcon,
    image: sahara,
    desc: 'The Alpine (battery powered) sensor can be fully kitted out to measure: <br /><ul><li>Carbon Dioxide (CO2) levels with a dual-channel NDIR chip (which achieves the accuracy to WELL Standard)</li><li>Temperaure</li><li>Relative Humidity</li></ul>',
  },
  BeaconTempHum: {
    label: 'Temperature and Humidity',
    brandName: 'Hoth',
    icon: AcUnitIcon,
    image: hoth,
    desc: 'The Hoth sensor measures temperature and relative humidity. Multiple temperature sensors in one space can be used to create and monitor spatial heatmaps, and balance floor heating or cooling, especially where solar gain is high.<br /><br />It is battery powered, lasts up to 5 years.<br /><br />Recommended placement is at shoulder height, away from heating or cooling sources.',
  },
  BeaconLightOnOff: {
    label: 'Light Level',
    brandName: 'Light Level',
    icon: WbIncandescentIcon,
  },
  OccSignatures: {
    label: 'Occupancy Signatures',
    brandName: 'Radar',
    icon: SettingsRemoteIcon,
    image: hal,
    desc: 'A ceiling-mounted sensor that uses radar to count the number of people in an area',
  },
  PIRMotionEvents: {
    label: 'Motion PIR',
    brandName: 'X1',
    icon: DirectionsRunIcon,
    image: x1,
    desc: 'The X1 sensor is designed to measure if a desk is occupied, not occupied, or how long since it was last occupied. It uses a passive infrared (PIR) sensor chip to detect the movement of heat.<br /><br />It is battery powered and lasts up to 5 years.<br /><br />It is designed to be easily stuck under a desk, but can also be placed in a small meeting room.',
  },
  virtual: {
    label: 'Virtual Sensor',
    brandName: 'virtual',
    icon: DirectionsRunIcon,
    desc: 'Sensors added from an external source',
  },
};

export enum SensorPropsType {
  port = 'port',
  hwVersion = 'hwVersion',
  fwVersion = 'fwVersion',
  location = 'location',
  name = 'name',
  shortName = 'shortName',
  latest = 'latest',
  id = 'id',
  timeZone = 'timeZone',
  type = 'type',
  data = 'data',
  metadata = 'metadata',
  gateway = 'gateway',
  position = 'position',
}

export const sensorProperties: Record<SensorPropsType, SensorProperty> = {
  port: {
    label: 'Port',
    editable: false,
    autofilled: true,
  },
  hwVersion: {
    label: 'Hardware Version',
    editable: false,
    autofilled: true,
  },
  fwVersion: {
    label: 'Firmware Version',
    editable: true,
    autofilled: false,
  },
  location: {
    label: 'Location',
    editable: true,
    autofilled: false,
  },
  name: {
    label: 'Name',
    editable: true,
    autofilled: false,
  },
  shortName: {
    label: 'Short Name',
    editable: true,
    autofilled: false,
  },
  latest: {
    label: 'Properties Version',
    editable: false,
    autofilled: false,
  },
  id: {
    label: 'Id',
    editable: false,
    autofilled: true,
  },
  timeZone: {
    label: 'Time Zone',
    editable: false,
    autofilled: true,
  },
  type: {
    label: 'Type',
    editable: false,
    autofilled: true,
  },
  data: {
    label: 'Data',
    editable: false,
    autofilled: false,
  },
  metadata: {
    label: 'Metadata',
    editable: false,
    autofilled: false,
  },
  gateway: {
    label: 'Gateway',
    editable: true,
    autofilled: true,
  },
  position: {
    label: 'Position',
    editable: false,
    autofilled: false,
  },
};

export const APListHeaderItem: APListHeader[] = [
  { id: 1, label: 'Listen', gridSize: 2 },
  { id: 2, label: 'Address', gridSize: 4 },
  { id: 3, label: 'Signal Strength (2.4/5GHz)', gridSize: 4 },
  { id: 4, label: 'SSIDs', gridSize: 2 },
];

export const APSubListHeaderItem: APListHeader[] = [
  { id: 1, label: 'Listen', gridSize: 2, xsGridSize: 2, hideInSmallScreen: false },
  { id: 2, label: 'Address', gridSize: 3, xsGridSize: 5, hideInSmallScreen: false },
  { id: 3, label: 'SSID', gridSize: 3, xsGridSize: 3, hideInSmallScreen: false },
  { id: 4, label: 'Channel', gridSize: 2, xsGridSize: 2, hideInSmallScreen: false },
  { id: 5, label: 'Last Seen', gridSize: 2, xsGridSize: 'auto', hideInSmallScreen: true },
];
