import dayjs from 'dayjs';
import { Theme } from '@mui/material';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Annotations, Layout, ColorScale, Shape, Data } from 'plotly.js';
import { HoursSelection } from '../../../state/types';
import {
  BandParamsType,
  dataColours,
  varNameBandParams,
  motionUtilisationBands,
} from '../../../utils/dataBandParams';
import { VarName } from '../../../utils/varNames';
import { CalendarSelectionType, PlotType, ValueType } from '../../CalendarView/helpers';
import { TimeRange } from '../plotCommon';
import { themeProps } from '../../../styles/theme';

dayjs.extend(localizedFormat);

const getDays = (totalDays: number, startDate: Date): Date[] => {
  const allDays: Date[] = [];
  for (let i = 0; i < totalDays; i++) {
    const newDay = new Date(dayjs(startDate).add(i, 'day').unix() * 1000);
    if (newDay) allDays.push(newDay);
  }
  return allDays;
};

export const getHourValues = (selectedHours: HoursSelection): number[] => {
  let hours;
  const { selectHours, startHour, endHour } = selectedHours;
  const allHours = Array.from(Array(24).keys());
  if (selectHours) hours = allHours.slice(startHour, endHour);
  else {
    hours = allHours;
  }
  return hours;
};

export const getDayOfWeekValues = (startDate: Date, endDate: Date): Date[] => {
  const rangeDays = dayjs(endDate).diff(startDate, 'day');
  let outputDays = rangeDays;
  if (rangeDays > 6) {
    outputDays = 7;
  } else {
    outputDays += 1;
  }
  // For one week it looks nice to have last day at end, for long times looks best to align
  const forceStartSunday = rangeDays > 13;
  return getDays(outputDays, forceStartSunday ? dayjs(startDate).day(0).toDate() : startDate);
};

const getHighlightedShades = (highlightedTimes: TimeRange[]): Shape[] => {
  const newShapes: Shape[] = [];
  if (highlightedTimes) {
    highlightedTimes.forEach(({ start, end }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newShapes.push({
        type: 'rect',
        xref: 'paper',
        yref: 'y',
        x0: 1,
        y0: dayjs(start).subtract(12, 'hours').toDate(),
        x1: 0,
        y1: dayjs(end).subtract(12, 'hours').toDate(),
        fillcolor: '#000',
        opacity: 0.3,
        line: { width: 0 },
      });
    });
  }
  return newShapes;
};

const getBaseLayout = (
  xValues: (Date | number)[],
  yValues: Date[],
  highlightedTimes: TimeRange[],
  theme: Theme
): Partial<Layout> => {
  const xMetric = 3; // 3 values for min/max/avg
  const yMetric = 1; // 1 values for avg
  const nX = xValues.length + xMetric;
  const nY = yValues.length + yMetric;
  const xBoxSize = 1 / (nX + 0.5); // 0.5 spacing between subplots
  const yBoxSize = 1 / (nY + 0.5); // 0.5 spacing between subplots
  const shapes = getHighlightedShades(highlightedTimes);
  return {
    font: { family: themeProps.fontFamily.body },
    shapes,
    annotations: [],
    xaxis: {
      ticks: '',
      // For hourly view have a tick for every hour
      nticks: xValues && typeof xValues[0] === 'number' ? xValues.length + 1 : undefined,
      side: 'top',
      domain: [0, xValues.length * xBoxSize],
      tickformatstops: [
        {
          dtickrange: [null, 'M1'],
          value: '%e %b',
        },
        {
          dtickrange: ['M1', 'M12'],
          value: '%e %b %y',
        },
        {
          dtickrange: ['M12', null],
          value: '%Y Y.',
        },
      ],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    xaxis2: {
      ticks: '',
      side: 'top',
      domain: [1 - xMetric * xBoxSize, 1],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    margin: {
      l: 40,
      r: 5,
      b: 5,
      t: 20,
      pad: 0,
    },
    height: 200,
    yaxis: {
      nticks: nY,
      tickformat: '%a',
      ticks: '',
      ticksuffix: ' ',
      autosize: false,
      autorange: 'reversed',
      domain: [1 - yValues.length * yBoxSize, 1],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    yaxis2: {
      ticks: '',
      autosize: false,
      autorange: 'reversed',
      domain: [0, yMetric * yBoxSize],
      tickfont: {
        color: theme.palette.text.primary,
      },
    },
    plot_bgcolor: theme.palette.primary.main,
    paper_bgcolor: theme.palette.primary.main,
  } as Partial<Layout>;
};

export const getWeekPlotLayout = (
  xValues: (Date | number)[],
  yValues: Date[],
  zValues: number[][],
  valueType: ValueType | undefined,
  highlightedTimes: TimeRange[],
  theme: Theme
): Partial<Layout> => {
  const layout = getBaseLayout(xValues, yValues, highlightedTimes, theme);
  if (zValues) {
    for (let i = 0; i < yValues.length; i++) {
      for (let j = 0; j < xValues.length; j++) {
        const currentValue = zValues?.[i]?.[j];
        let textLabel = '';
        if (currentValue === -Infinity) textLabel = 'n/a';
        else if (valueType === ValueType.occ) {
          if (currentValue === 0) textLabel = 'Avai';
          else if (currentValue === 100) textLabel = 'Occ';
        } else textLabel = zValues?.[i]?.[j]?.toFixed(1) ?? '';
        const result = {
          xref: 'x',
          yref: 'y',
          x: xValues[j],
          y: yValues[i],
          text: textLabel,
          font: {
            size: 10,
            color: 'white',
          },
          showarrow: false,
        } as unknown as Partial<Annotations>;
        layout?.annotations?.push(result);
      }
    }
  }
  return layout;
};

export const getCalendarPlotLayout = (
  xValues: (Date | number)[],
  yValues: Date[],
  zValues: number[][],
  isLongRange: boolean,
  highlightedTimes: TimeRange[],
  valueType: ValueType | undefined,
  theme: Theme
): Partial<Layout> => {
  const layout = getBaseLayout(xValues, yValues, highlightedTimes, theme);
  if (zValues) {
    for (let i = 0; i < yValues.length; i++) {
      for (let j = 0; j < xValues.length; j++) {
        let textLabel = '';
        const currentValue = zValues?.[i]?.[j];
        if (!isLongRange) {
          if (currentValue === -Infinity) textLabel = 'n/a';
          else if (valueType === ValueType.occ) {
            if (currentValue === 0) textLabel = 'Avai';
            else if (currentValue === 100) textLabel = 'Occ';
          } else textLabel = zValues?.[i]?.[j]?.toFixed(1) ?? '';
        }
        const result = {
          xref: 'x',
          yref: 'y',
          x: xValues[j],
          y: yValues[i],
          text: textLabel,
          font: {
            size: 10,
            color: 'white',
          },
          showarrow: false,
        } as unknown as Partial<Annotations>;
        layout?.annotations?.push(result);
      }
    }
  }
  return layout;
};

export const getSubPlotResult = (
  xValues: Date[] | string[] | number[],
  yValues: Date[] | string[],
  zValues: number[][],
  xRef: string,
  yRef: string,
  isLongRange: boolean,
  valueType: ValueType | undefined
): Partial<Annotations>[] => {
  const annotations = [];
  if (zValues) {
    for (let i = 0; i < yValues.length; i++) {
      for (let j = 0; j < xValues.length; j++) {
        let textLabel = '';
        const currentValue = zValues?.[i]?.[j];
        if (!isLongRange) {
          if (currentValue === -Infinity) textLabel = 'n/a';
          else if (valueType === ValueType.occ) {
            if (currentValue === 0) textLabel = 'Avai';
            else if (currentValue > 0) textLabel = 'Occ';
          } else textLabel = zValues?.[i]?.[j]?.toFixed(1) ?? '';
        }

        const result = {
          xref: xRef,
          yref: yRef,
          x: xValues[j],
          y: yValues[i],
          text: textLabel,
          font: {
            size: 10,
            color: 'white',
          },
          showarrow: false,
        } as unknown as Partial<Annotations>;
        annotations.push(result);
      }
    }
  }
  return annotations;
};

export const getZmaxValue = (
  data: (number | null)[] | undefined,
  varName: VarName
): number | undefined => {
  let maxValue;
  let bands = varNameBandParams[varName];
  if (varName === VarName.MotionEvent) bands = motionUtilisationBands;
  if (varName === VarName.OnlineStatus) maxValue = 1;
  else if (bands) {
    for (let i = 0; i < bands.length; i++) {
      const { upperBound } = bands[i];
      if (maxValue && upperBound > maxValue && upperBound !== Infinity) maxValue = upperBound;
      else if (maxValue === undefined && upperBound !== Infinity) maxValue = upperBound;
    }
  } else if (data) {
    for (let i = 0; i < data.length; i++) {
      const dataValue = data[i];
      if (dataValue !== null) {
        if (maxValue === undefined) maxValue = dataValue;
        else if (dataValue > maxValue) {
          maxValue = dataValue;
        }
      }
    }
  }
  return maxValue;
};

export const getColorScaleValue = (
  varName: VarName,
  minValue: number | undefined,
  maxValue: number | undefined,
  showGradient: boolean | undefined
): (string | number)[][] => {
  let bands;
  if (varName === VarName.MotionEvent) bands = motionUtilisationBands;
  else bands = varNameBandParams[varName];
  const colorScale: (string | number)[][] = [];
  if (minValue !== undefined && maxValue !== undefined) {
    const bandWithLowerBound = bands ? [...bands] : [];
    const lowestValue = minValue - 0.1;
    const lowerBound: BandParamsType = {
      upperBound: lowestValue,
      color: dataColours.grey,
      text: 'N/A',
      label: 'N/A',
    };
    bandWithLowerBound.unshift(lowerBound);
    if (!bands) {
      bandWithLowerBound.push({
        upperBound: minValue,
        color: '#9db7cc', // setting default min value colorscale to custom light blue
        text: 'N/A',
        label: 'N/A',
      });
      bandWithLowerBound.push({
        upperBound: maxValue,
        color: dataColours.blue,
        text: 'N/A',
        label: 'N/A',
      });
      bandWithLowerBound.push({
        upperBound: Infinity,
        color: dataColours.green,
        text: 'N/A',
        label: 'N/A',
      });
    }

    for (let i = 0; i < bandWithLowerBound.length; i++) {
      const { upperBound } = bandWithLowerBound[i];
      let gradientBound = upperBound;

      if (i === 0) gradientBound = upperBound;
      else if (i !== 0 && i < bandWithLowerBound.length)
        gradientBound =
          (bandWithLowerBound[i - 1].upperBound + bandWithLowerBound[i].upperBound) / 2;
      let colorBound = upperBound;
      if (showGradient) colorBound = gradientBound;
      if (minValue !== undefined && maxValue !== undefined) {
        const roundUpScale = (colorBound - lowestValue) / (maxValue - lowestValue);
        if (!showGradient) {
          if (upperBound !== Infinity && roundUpScale !== 1) {
            colorScale.push([roundUpScale, bandWithLowerBound[i].color]);
            colorScale.push([roundUpScale, bandWithLowerBound[i + 1].color]);
          } else if (roundUpScale === 1) {
            colorScale.push([0.999999999, bandWithLowerBound[i].color]);
            colorScale.push([1, bandWithLowerBound[i + 1].color]);
          } else if (varName === VarName.OnlineStatus) {
            colorScale.push([1, bandWithLowerBound[i].color]);
          }
        } else if (showGradient) {
          if (i === 0) {
            colorScale.push([roundUpScale, bandWithLowerBound[i].color]);
            colorScale.push([roundUpScale, bandWithLowerBound[i + 1].color]);
          } else if (upperBound !== Infinity) {
            colorScale.push([roundUpScale, bandWithLowerBound[i].color]);
          } else if (upperBound === Infinity) colorScale.push([1, bandWithLowerBound[i].color]);
        }
      }
    }
  }
  return colorScale;
};

export const getHighlightedItems = (yValues: Date[], includeWeekends: boolean) => {
  const highlightedTimes: TimeRange[] = [];
  if (!includeWeekends) {
    const weekendValues = yValues.filter(
      (day) => dayjs(day).get('day') === 0 || dayjs(day).get('day') === 6
    );

    if (weekendValues.length > 0) {
      for (let i = 0; i < weekendValues.length; i++) {
        highlightedTimes.push({
          start: dayjs(weekendValues[i]).startOf('day').unix() * 1000,
          end: dayjs(weekendValues[i]).endOf('day').unix() * 1000,
        });
      }
    }
  }
  return highlightedTimes;
};

export const getMainPlotData = (
  colorscaleValue: (string | number)[][],
  xValues: (Date | number)[],
  yValues: Date[],
  plotValues: number[][] | undefined,
  minValue: number,
  maxValue: number | undefined,
  isTot: boolean,
  varNameMetric: string | null
): Data | undefined => {
  const labelMap: string[][] = [];
  yValues.forEach((dayOfWeek) => {
    const colArray: string[] = [];
    xValues.forEach((col) => {
      // CHEAT to determine if it is Calendar plot
      if (col instanceof Date) {
        colArray.push(dayjs(col).add(dayOfWeek.getDay(), 'day').format('YYYY-MM-DD'));
      }
    });
    if (colArray.length > 0) labelMap.push(colArray);
  });
  const statType = isTot ? 'Total' : 'Average';
  let plotlyData: Data | undefined;
  if (colorscaleValue) {
    plotlyData = {
      x: xValues,
      y: yValues,
      z: plotValues,
      hovertemplate:
        labelMap.length > 0
          ? `%{customdata}<br><b>${statType}:</b> %{z} ${varNameMetric}<extra></extra>`
          : `The hour from: %{x}:00<br>On all selected: %{y}<br>${statType} Value: %{z} ${varNameMetric}<extra></extra>`,
      customdata: labelMap,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: minValue !== undefined ? minValue : 0,
      zmax: maxValue !== undefined ? maxValue : 1,
    };
  }
  return plotlyData;
};

export const getMinMaxAvgPerDayData = (
  colorscaleValue: (string | number)[][],
  plotValues: number[][],
  days: Date[],
  zmin: number,
  zmaxIn: number | undefined,
  isTot: boolean,
  varNameMetric: string | null
): Data | undefined => {
  let plotlyData: Data | undefined;
  let zmax = zmaxIn !== undefined ? zmaxIn : 1;
  if (isTot) zmax = Math.max(...plotValues.flat());
  if (colorscaleValue) {
    plotlyData = {
      x: isTot ? [PlotType.tot] : [PlotType.min, PlotType.avg, PlotType.max],
      xaxis: 'x2',
      y: days,
      z: plotValues,
      hovertemplate: `${isTot ? 'Total' : '%{x}'} %{z} ${varNameMetric}<extra></extra>`,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin,
      zmax,
    };
  }
  return plotlyData;
};

export const getAvgPerColumnData = (
  colorscaleValue: (string | number)[][],
  plotValues: number[][],
  xValues: (Date | number)[],
  minValue: number,
  maxValue: number | undefined,
  isTot: boolean,
  varNameMetric: string | null
): Data | undefined => {
  let plotlyData: Data | undefined;
  let zmax = maxValue !== undefined ? maxValue : 1;
  if (isTot) zmax = Math.max(...plotValues.flat());
  if (colorscaleValue && plotValues) {
    plotlyData = {
      x: xValues,
      y: isTot ? ['Total'] : [PlotType.avg],
      hovertemplate: `${isTot ? 'Total' : 'Average'} %{z} ${varNameMetric}<extra></extra>`,
      yaxis: 'y2',
      z: plotValues,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: minValue !== undefined ? minValue : 0,
      zmax,
    };
  }
  return plotlyData;
};

export const getMinMaxAvgPerColumnData = (
  colorscaleValue: (string | number)[][],
  plotValues: number[][],
  minValue: number,
  maxValue: number | undefined,
  isTot: boolean,
  varNameMetric: string | null
): Data | undefined => {
  let plotlyData: Data | undefined;
  if (colorscaleValue && plotValues) {
    plotlyData = {
      x: isTot ? [PlotType.tot] : [PlotType.min, PlotType.avg, PlotType.max],
      y: isTot ? ['Total'] : [PlotType.avg],
      hovertemplate: `${isTot ? 'Total' : '%{x}'} %{z} ${varNameMetric}<extra></extra>`,
      xaxis: 'x2',
      yaxis: 'y2',
      z: plotValues,
      xgap: 3,
      ygap: 3,
      type: 'heatmap',
      colorscale: colorscaleValue as ColorScale,
      showscale: false,
      zmin: minValue !== undefined ? minValue : 0,
      zmax: maxValue !== undefined ? maxValue : 1,
    };
  }
  return plotlyData;
};

export const getCsvData = (
  minValue: number,
  mainPlotXValues: (Date | number)[],
  mainPlotYValues: Date[],
  mainPlotZValues: number[][] | undefined,
  minMaxAvgPerDay: number[][],
  avgPerHourColumn: number[][],
  minMaxAvgPerColumn: number[][],
  selectionType: CalendarSelectionType
) => {
  let exportPlotData =
    selectionType === CalendarSelectionType.week ? 'Day/Hour,' : 'Day/Start of Week,';
  if (mainPlotZValues) {
    // generate header line
    for (let i = 0; i < mainPlotXValues.length; i++) {
      if (selectionType === CalendarSelectionType.week) {
        exportPlotData += `${JSON.stringify(mainPlotXValues[i])},`;
      } else exportPlotData += `${dayjs(mainPlotXValues[i]).format('DD/MM/YYYY')},`;
    }
    exportPlotData += ', Min, Max, Avg\r\n\n';
    // add plot values to table starting with day
    for (let i = 0; i < mainPlotYValues.length; i++) {
      exportPlotData += `${dayjs(mainPlotYValues[i]).format('dddd')},`;
      const mainPlotValues = mainPlotZValues[i];
      const minMaxAvgPlotValues = minMaxAvgPerDay[i];
      for (let j = 0; j < mainPlotValues.length; j++) {
        let plotValue = mainPlotValues[j].toFixed(1);
        if (Number(mainPlotValues[j].toFixed(1)) === minValue - 0.1) plotValue = 'n/a';
        exportPlotData += `${plotValue},`;
      }
      exportPlotData += ',';
      for (let j = 0; j < minMaxAvgPlotValues.length; j++) {
        exportPlotData += `${minMaxAvgPlotValues[j]}${
          j === minMaxAvgPlotValues.length - 1 ? '' : ','
        }`;
      }
      exportPlotData += '\r\n';
    }

    // add average values from the subplot to table
    exportPlotData += '\nAverage,';
    const avgPerHourColumnPlotValues = avgPerHourColumn[0];
    for (let i = 0; i < avgPerHourColumnPlotValues.length; i++) {
      exportPlotData += `${avgPerHourColumnPlotValues[i]},`;
    }
    exportPlotData += ',';
    const minMaxAvgPerColumnPlotValues = minMaxAvgPerColumn[0];
    for (let i = 0; i < minMaxAvgPerColumnPlotValues.length; i++) {
      exportPlotData += `${minMaxAvgPerColumnPlotValues[i]}${
        i === minMaxAvgPerColumnPlotValues.length - 1 ? '' : ','
      }`;
    }
  }
  return exportPlotData;
};

export function getDatesArray(startDate: Date, endDate: Date): Date[] {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}
