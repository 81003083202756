import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import posthog from 'posthog-js';
import {
  fetchUserDetails,
  fetchLocations,
  fetchAllSensorsInLocation,
  fetchLocationProperties,
  fetchAvailableVars,
} from '../services/apiService';
import { setUserPublic, setLocationData, setSensorsById, setAvailableVars } from '../state/actions';
import useStyles from '../styles';
import Compare from '../Views/Compare';
import LocationConfig from '../Views/LocationConfig';
import SensorConfig from '../Views/SensorConfig';
import ProvisionSensor from '../Views/ProvisionSensor';
import MobileViewDrawer from '../Widgets/Navigation/MobileViewDrawer';
import DesktopViewDrawer from '../Widgets/Navigation/DesktopViewDrawer';
import MainWrapper from './MainWrapper';
import CalendarView from '../Views/CalendarView';
import UserProfile from '../Views/UserProfile';
import AppSensorInfo from '../Views/AppSensorInfo';
import AppInstallSensor from '../Views/AppInstallSensor';
import AppAdminSensor from '../Views/AppAdminSensor';
import ActiveSource from '../Widgets/ActiveSource/ActiveSource';
import ListViewDashboard from '../Views/ListViewDashboard';
import Dashboard from '../Views/Dashboard';
import { SensorLatest } from '../services/api';

interface ShellProps {
  userName: string;
}

const routes = [
  {
    id: 1,
    url: '/compare',
    helmetTitle: 'Compare | LightFi',
    component: Compare,
  },
  {
    id: 2,
    url: '/location/:locationId',
    helmetTitle: 'Location Config | LightFi',
    component: LocationConfig,
  },
  {
    id: 3,
    url: '/location',
    helmetTitle: 'Location Config | LightFi',
    component: LocationConfig,
  },
  {
    id: 4,
    url: '/sensors/:sensorId',
    helmetTitle: 'Sensor Config | LightFi',
    component: SensorConfig,
  },
  {
    id: 5,
    url: '/provision/:sensorId',
    helmetTitle: 'Provision | LightFi',
    component: ProvisionSensor,
  },
  {
    id: 6,
    url: '/userProfile',
    helmetTitle: 'User Profile | LightFi',
    component: UserProfile,
  },
  {
    id: 7,
    url: '/calendarView',
    helmetTitle: 'Calendar View | LightFi',
    component: CalendarView,
  },
  {
    id: 8,
    url: '/appSensorInfo/:displayType/:selectedSensorId',
    helmetTitle: 'App Sensor Details | LightFi',
    component: AppSensorInfo,
  },
  {
    id: 9,
    url: '/install/:sensorId',
    helmetTitle: 'Install Sensor | LightFi',
    component: AppInstallSensor,
  },
  {
    id: 10,
    url: '/install',
    helmetTitle: 'Install Sensor | LightFi',
    component: AppInstallSensor,
  },
  {
    id: 11,
    url: '/appAdmin/:sensorId',
    helmetTitle: 'App Admin Sensor | LightFi',
    component: AppAdminSensor,
  },
  {
    id: 12,
    url: '/list',
    helmetTitle: 'List View Dashboard | LightFi',
    component: ListViewDashboard,
  },
  {
    id: 13,
    url: '/activeSource',
    helmetTitle: 'Active Source Dashboard | LightFi',
    component: ActiveSource,
  },
  {
    id: 14,
    url: '/',
    helmetTitle: 'Dashboard | LightFi',
    component: Dashboard,
  },
];

function Shell({ userName }: ShellProps): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (userName) {
      fetchUserDetails()
        .then((userDetails) => {
          dispatch(setUserPublic(userDetails));
        })
        .catch(() => {
          dispatch(setUserPublic(null));
        });

      fetchLocationProperties('#').then((location) => {
        // Add a property for the user to help identify their location in posthog
        posthog.capture('Root Location', { rootLocName: location?.name });
      });

      fetchLocations()
        .then((locations) => {
          dispatch(setLocationData(locations));
        })
        .catch(() => {
          dispatch(setLocationData(null));
        });

      fetchAllSensorsInLocation('#', ['name', 'location'])
        .then((sensors) => {
          const sensorProps = sensors.sensors ?? [];
          dispatch(setSensorsById(sensorProps as SensorLatest[]));
        })
        .catch(() => null);

      fetchAvailableVars()
        .then((vars) => {
          dispatch(setAvailableVars(vars));
        })
        // keep all vars for error
        .catch(() => dispatch(setAvailableVars([])));
    }
  }, [userName, dispatch]);

  return (
    <Router>
      <>
        <MobileViewDrawer />
        <DesktopViewDrawer />
        <div className={`${classes.shell} no-print`}>
          <Routes>
            {routes.map((route) => (
              <Route
                path={route.url}
                key={route.id}
                element={
                  <MainWrapper
                    routeComponent={<route.component />}
                    helmetTitle={route.helmetTitle}
                  />
                }
              />
            ))}
          </Routes>
        </div>
      </>
    </Router>
  );
}

export default Shell;
