import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useMediaQuery from '@mui/material/useMediaQuery';

import useStyles from '../styles';
import ReportsIcon from '../styles/icons/ReportsIcon';
import Header from '../components/Header';
import DateRangePicker, { MetricOptions } from '../components/DateRangePicker';
import SensorDataPicker from '../Widgets/Compare/SensorDataPicker';
import SensorDataPlotWrapper from '../Widgets/Compare/SensorDataPlotWrapper';
import MetricDataPlotWrapper from '../Widgets/Compare/MetricDataPlotWrapper';
import { getActiveMarker, getActivePlotVars } from '../state/selectors';
import { toggleActivePlotVar } from '../state/actions';

const SensorList = lazy(() => import('../Widgets/Compare/SensorList'));

function Compare(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const activePlotVars = useSelector(getActivePlotVars);
  const activeMarker = useSelector(getActiveMarker);

  const [showSensorsList, setShowSensorsList] = useState(false);
  const [showFloatingBtns, setShowFloatingBtns] = useState(false);
  const [metricSelection, setMetricSelection] = useState<MetricOptions>(MetricOptions.Raw);

  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    if (isDesktopView) setShowSensorsList(true);
  }, [isDesktopView]);

  const handleScroll = (event: React.UIEvent | React.TouchEvent) => {
    const target = event.target as HTMLElement;
    const { scrollHeight, scrollTop, clientHeight } = target;
    const scrollPos = scrollHeight - scrollTop - clientHeight;
    const topRect = scrollHeight - clientHeight;
    if (isDesktopView) {
      if (scrollPos !== topRect) setShowFloatingBtns(true);
      else setShowFloatingBtns(false);
    }
  };

  // for mobile screens scroll seems ineffective and compare from screen height instead
  const controlDirection = () => {
    if (!isDesktopView) {
      if (window.pageYOffset > window.innerHeight / 4) setShowFloatingBtns(true);
      else setShowFloatingBtns(false);
    }
  };

  useEffect(() => {
    // when landing, check and add active marker to varlist if missing
    if (activePlotVars.indexOf(activeMarker) === -1) dispatch(toggleActivePlotVar(activeMarker));
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.bodyContent} style={{ overflowY: 'hidden' }}>
      <Header />
      <div className={`${classes.dashboard} ${classes.reportContainer}`}>
        {showSensorsList && (
          <div
            onScroll={handleScroll}
            onTouchMove={handleScroll}
            className={`${classes.dashboardTile} ${classes.dashboardTileScrollable}`}
            style={{ minWidth: isDesktopView ? '400px' : '300px', flex: '1 1 auto' }}
          >
            <Suspense
              fallback={<Skeleton variant="rectangular" className={classes.compareListPanel} />}
            >
              <SensorList
                showFloatingBtns={showFloatingBtns}
                hideList={() => setShowSensorsList(false)}
              />
            </Suspense>
          </div>
        )}
        <div
          className={`${classes.dashboardTile} ${classes.dashboardTileScrollable} ${classes.marginLeftNoSm} ${classes.compareRightContainer}`}
          style={{
            display: `${!isDesktopView && showSensorsList ? 'none' : 'block'}`,
            width: '100%',
          }}
        >
          <DateRangePicker
            allowLongTime={metricSelection === MetricOptions.Daily}
            metricSelection={metricSelection}
            setMetricSelection={setMetricSelection}
          />
          <SensorDataPicker />
          <div style={{ position: 'relative' }}>
            {metricSelection === MetricOptions.Raw ? (
              <SensorDataPlotWrapper />
            ) : (
              <MetricDataPlotWrapper metricSelection={metricSelection} />
            )}
          </div>
        </div>
      </div>
      {/* Button to show/hide sensors list */}
      <Tooltip title={`${showSensorsList ? 'Hide Sensors list' : 'Show Sensors List'}`}>
        <Fab
          size="medium"
          className={classes.floatingBtn}
          style={{
            textTransform: 'inherit',
            zIndex: 10000,
            left: `${isDesktopView && !showSensorsList ? '75px' : 'unset'}`,
            display: `${isDesktopView && !showSensorsList ? 'block' : ''}`,
          }}
          onClick={() => setShowSensorsList(!showSensorsList)}
        >
          {showSensorsList ? <ReportsIcon /> : <FormatListBulletedIcon />}
        </Fab>
      </Tooltip>
    </div>
  );
}
export default Compare;
