import React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useGlobalStyles from '../styles';
import useStyles from '../styles/dashboard';
import Header from '../components/Header';
import SummaryCardContainer from '../Widgets/Dashboard/SummaryCardContainer';
import VarNamePlotContainer from '../Widgets/Dashboard/VarnamePlotContainer';
import DashboardSensorsList from '../Widgets/Dashboard/DashboardSensorsList';
import MapGL from '../Widgets/Map/Map';

function Dashboard(): JSX.Element {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <div
      style={{ overflowY: 'hidden', height: '100%', padding: '0' }}
      className={globalClasses.bodyContent}
    >
      <Box sx={{ overflowY: 'scroll', padding: '10px' }}>
        <Header />
        <Grid container spacing={2} style={{ textAlign: 'center', padding: '10px 0' }}>
          <SummaryCardContainer />
          {/* Display Coloured line plot with varName icon buttons */}
          <Grid item xs={12} md={12} lg={9}>
            <VarNamePlotContainer />
          </Grid>

          {/* display sensors summary list with sorting options */}
          <Grid item xs={12} md={12} lg={3}>
            <DashboardSensorsList />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card className={classes.dashboardCard} style={{ height: '500px' }}>
              <MapGL />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Dashboard;
