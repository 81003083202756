import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { CalendarSelectionType } from '../CalendarView/helpers';
import useStyles from '../../styles/calendarView';

interface CalendarBtnGroupProps {
  selectionType: CalendarSelectionType;
  setSelectionType: (value: CalendarSelectionType) => void;
}

function CalendarBtnGroup({ selectionType, setSelectionType }: CalendarBtnGroupProps): JSX.Element {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      size="small"
      value={CalendarSelectionType}
      style={{ marginTop: '5px' }}
      color="primary"
    >
      <ToggleButton
        value={CalendarSelectionType.week}
        onClick={() => setSelectionType(CalendarSelectionType.week)}
        selected={selectionType === CalendarSelectionType.week}
        className={classes.toggleBtn}
      >
        WeekDays
      </ToggleButton>
      <ToggleButton
        value={CalendarSelectionType.calendar}
        onClick={() => setSelectionType(CalendarSelectionType.calendar)}
        selected={selectionType === CalendarSelectionType.calendar}
        className={classes.toggleBtn}
      >
        Calendar
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default CalendarBtnGroup;
