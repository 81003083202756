import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DateRangePicker from '../../components/DateRangePicker';
import CalendarBtnGroup from '../HelperComponents/CalendarBtnGroup';
import { CalendarSelectionType } from './helpers';
import useStyles from '../../styles/calendarView';

interface CalendarTimeSelectionProps {
  selectionType: CalendarSelectionType;
  setSelectionType: (value: CalendarSelectionType) => void;
  setDownloadData: (value: boolean) => void;
}

function CalendarTimeSelection({
  selectionType,
  setSelectionType,
  setDownloadData,
}: CalendarTimeSelectionProps): JSX.Element {
  const classes = useStyles();

  return (
    <div>
      <Box sx={{ margin: '0 10px' }}>
        <DateRangePicker allowLongTime />
      </Box>
      <div className={classes.timeSelectionContainer}>
        <Box sx={{ display: 'flex' }}>
          <CalendarBtnGroup selectionType={selectionType} setSelectionType={setSelectionType} />
          <Tooltip title="Download data in .csv format">
            <IconButton
              color="primary"
              aria-label="Download data"
              onClick={() => setDownloadData(true)}
              size="large"
              style={{ marginLeft: '10px' }}
            >
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </div>
  );
}

export default CalendarTimeSelection;
